import styled from 'styled-components';

const InputAndButton = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  div:first-of-type {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    div:first-of-type {
      max-width: 200px;
      margin-bottom: 5px;
    }

    button, a[role=button] {    
      margin-left: 10px;
    }
  }
`;

export default InputAndButton;
