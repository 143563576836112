import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { Theme } from '@beelineloans/cx-library/dist/theme/default';

const Wrap = styled.div`
  flex-basis: 100%;
  max-width: 100%;
  box-sizing: border-box;
  margin-top: 27px;
  margin-bottom: 30px;
`;

const Dots = styled.section`
  text-align: left;
  margin: 0px;
  display: flex;
  justify-content: center;
`

const Dot = styled.div`
  height: 10px;
  width: 10px;
  border-radius: 50%;
  margin-right: 5px;
  border: 1px solid ${props => props.$borderColour};

  ${props => props.$fill && css`
  background-color: ${props => props.$bgColour};
  `}
`;

const ProgressDots = ({ bgColour, borderColour, totalIntroCount, currentIntroIndex }) => {
  return (
    <Wrap>
      <Dots>
        {[...Array(totalIntroCount)].map((_, idx) =>
          <Dot key={`dot${idx}`} $bgColour={bgColour} $borderColour={borderColour} $fill={currentIntroIndex === idx} />
        )}
      </Dots>
    </Wrap>
  );
};

ProgressDots.defaultProps = {
  bgColour: Theme.colours.background.navy,
  borderColour: Theme.colours.background.navy,
  totalIntroCount: 4,
  currentIntroIndex: 0,
};

ProgressDots.propTypes = {
  bgColour: PropTypes.string,
  borderColour: PropTypes.string,
  totalIntroCount: PropTypes.number,
  currentIntroIndex: PropTypes.number,
};

export default ProgressDots;