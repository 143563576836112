import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { pushDL } from '../../helpers';
import RadioField from '../RadioField';
import { H2 } from '@beelineloans/cx-library/dist/components/typography';
import { useSessionState } from '../../state/StateProvider';
import { URLS, ACTIONS, propertyOccupancyOptions, propertyOccupancyOptionsWithSecondHome } from '../../const';
import Banner from '../../images/banner8.svg';

const Radio = styled(RadioField)`
  input {
    max-width: 300px;
  }
`;

const Step = () => {
  const [{ profile, sourceId }, dispatch] = useSessionState();
  const oldValue = profile.propertyOccupancy;
  const navigate = useNavigate();

  useEffect(() => {
    dispatch({
      type: ACTIONS.PAGE,
      data: {
        image: sourceId ? null : Banner,
      },
      backButton: true,
      progress: 45,
      sourceId,
    });
  }, []);

  const handleInputChange = (field) => (event) => {
    let newValue = event.target.value;
    let resetValues = {};
    if (oldValue !== undefined && oldValue !== newValue) {
      resetValues = {
        isDSCR: null,
        estimatedRental: null
      }
    }
    dispatch({
      type: ACTIONS.STORE_PROFILE,
      data: {
        ...profile,
        [field]: newValue,
        ...resetValues
      }
    });
    pushDL('submitForm', {
      FormBody: {
        [(profile.applicationType === 'refi' && profile.propertyType !== 'MultiFamily') ? 'refinanceCurrentlyLiving' : 'planning']: newValue
      }
    }, null);
    let next = '';
    if (newValue === 'Primary')
      next = profile.propertyType === 'MultiFamily' ? URLS.APPLY_NINE_RENTALINCOME : (profile.applicationType === 'refi' ? URLS.APPLY_ELEVEN : URLS.APPLY_TEN);
    if (newValue === 'Investment') next = URLS.APPLY_NINE_RENTALINCOME;
    if (newValue === 'Second Home') next = profile.applicationType === 'refi' ? URLS.APPLY_ELEVEN : URLS.APPLY_TEN;
    navigate(next);
  };

  return (
    <>
      {(profile.applicationType === 'purchase' || profile.propertyType === 'MultiFamily') && (
        <H2 bold>
          And are you planning to live there?
        </H2>
      )}
      {profile.applicationType === 'refi' && profile.propertyType !== 'MultiFamily' && (
        <H2 bold>
          Are you currently living there?
        </H2>
      )}
      <Radio
        id="propertyOccupancy"
        onClick={handleInputChange('propertyOccupancy')}
        options={profile.propertyType === 'MultiFamily' ? propertyOccupancyOptions : propertyOccupancyOptionsWithSecondHome}
        selectedValue={`${profile.propertyOccupancy}`}
        border={false}
      />
    </>
  );
};

export default Step;