import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { TextField } from '@beelineloans/cx-library/dist/components/forms/fields';
import PoweredByGoogle from '../images/poweredByGoogle';
import MapPin from '../images/mapPin';

const LookupWrapper = styled.ul`
  list-style:     none;
  position:       relative;
  background:     ${props => props.theme.colours.background.navy};
  z-index:        1;
  color:          ${props => props.theme.colours.text.light};
  width:          calc(100% - 60px);
  padding:        0;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  top:            -50px;
  margin-top:     0;
  margin-left:    30px;
`;

const LookupItem = styled.li`
  line-height:  26px;
  padding:      5px;
  font-weight:  500;
  cursor:       pointer;

  &:hover {
    background: ${props => props.theme.colours.background.navy};
    color:      ${props => props.theme.colours.text.light};
  }
`;

const MapDiv = styled.div`
  width:        100%;
  height:       120px;
  min-height:   120px;
  display:      ${props => props.show ? 'block' : 'none'};
  margin-bottom: 28px;
  transition-property: height;
  transition-duration: 400ms;
  margin-left: calc((100% - 100vw) / 2);
  width: 100vw;

  @media only screen and (${props => props.theme.mediaBreakpoints.desktop}) {
    margin-left: calc((100% - 50vw) / 2);
    width: 50vw;
  }
`;

const PinDiv = styled.div`
  width: 20px;
  height: 20px;
  overflow: hidden;
  position: absolute;
  left: -10px;
  top: -20px;
  z-index: 100;
  display: none;
`;

const PinImg = styled.img`
  position: absolute;
  left: 0px;
  top: 0px;
  user-select: none;
  width: 20px;
  height: 20px;
  border: 0px;
  padding: 0px;
  margin: 0px;
  max-width: none;
  opacity: 1;
`;

const InLine = styled.div`
`;

const LookupInput = styled(TextField)`
  margin-bottom: 0;

  ${props =>
    props.$isAddress
    && css`
      display:inline-block;
      width: 66%;
      max-width: 400px;
      padding-right: 8%;
		`}

  ${props =>
    props.$isUnitNumber
    && css`
      display:inline-block;
      width: 26%;
    `}
`;

const ImgWrap = styled.div`
  padding-left: 0px;
  margin-top: 10px;
  margin-bottom: 0px;
`;

const Image = styled.img`
  width: 80px;
`;

const AddressLookup = ({ address, unitNumber, placeType, onAddrChange, onSelect, onUnitNumberChange, error }) => {
  const replaceUSA = (address) => address?.replace(', United States', '').replace(', USA', '').replace(', US', '');
  const [workingAddress, setWorkingAddress] = useState(replaceUSA(address));
  const [workingUnitNumber, setWorkingUnitNumber] = useState(unitNumber);
  const [showMapDiv, setShowMapDiv] = useState(false);
  const ref = useRef(null);
  const [map, setMap] = useState();
  const mapZoom = 15;

  useEffect(() => {
    if (ref.current && !map) {
      setMap(new window.google.maps.Map(ref.current, {
        zoom: mapZoom,
        disableDefaultUI: true,
      }));
    }
  });

  const handleAddressChange = value => {
    setWorkingAddress(value);
    setShowMapDiv(false);
    onAddrChange({
      target: {
        value
      }
    });
  };

  const handleUnitNumberChange = (event) => {
    let newValue = event.target.value;
    setWorkingUnitNumber(newValue);
    onUnitNumberChange(newValue);
  };

  const handleSelect = async (addr) => {
    await geocodeByAddress(addr)
      .then(results => {
        setWorkingAddress(replaceUSA(results[0].formatted_address));
        setShowMapDiv(true);
        onSelect(results[0], results[0].formatted_address);
        return getLatLng(results[0]);
      })
      .then(latLng => map.panTo(latLng))
      .catch(e => {
        console.info('e', e)
      })
  };

  return (
    <>
      <PlacesAutocomplete
        value={workingAddress}
        onChange={handleAddressChange}
        onSelect={handleSelect}
        shouldFetchSuggestions={workingAddress && workingAddress.length >= 1}
        searchOptions={{
          types: [placeType],
          componentRestrictions: {
            country: ['us'],
          },
        }}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => {
          return (
            <>
              <>
                <MapDiv ref={ref} id='gmap' show={showMapDiv} />
                <PinDiv>
                  <PinImg src={MapPin} draggable="false" />
                </PinDiv>
              </>
              <InLine>
                <LookupInput
                  id="address"
                  $isAddress={placeType === 'address'}
                  placeholder={placeType === 'address' ? 'Enter your address' : 'Search by ZIP or City, State'}
                  value={workingAddress}
                  className="addressLookup"
                  autocomplete="false"
                  autoFocus
                  border={true}
                  error={error}
                  {...getInputProps({})}
                >
                </LookupInput>
                {
                  placeType === 'address' && (
                    <LookupInput
                      id="unitNumber"
                      $isUnitNumber={true}
                      placeholder="Apt/Unit #"
                      value={workingUnitNumber}
                      className="addressLookup"
                      autocomplete="false"
                      border={true}
                      onChange={handleUnitNumberChange}
                    >
                    </LookupInput>
                  )
                }
              </InLine>
              <ImgWrap>
                <Image src={PoweredByGoogle} alt='powered by Google' draggable='false' />
              </ImgWrap>
              <LookupWrapper>
                {loading && <LookupItem key={`loading_${loading}`}>Loading...</LookupItem>}
                {suggestions.map(suggestion => (
                  <LookupItem key={suggestion.place_id} {...getSuggestionItemProps(suggestion)}>
                    {replaceUSA(suggestion.description)}
                  </LookupItem>
                ))}
              </LookupWrapper>
            </>
          )
        }}
      </PlacesAutocomplete>
    </>
  );
};

AddressLookup.propTypes = {
  onAddrChange: PropTypes.func,
  onSelect: PropTypes.func,
  onUnitNumberChange: PropTypes.func,
  address: PropTypes.string.isRequired,
  placeType: PropTypes.string,
  error: PropTypes.string,
};

AddressLookup.defaultProps = {
  onAddrChange: () => {
  },
  onSelect: () => {
  },
  onUnitNumberChange: () => {
  },
  placeType: 'address',
  error: null
};

export default AddressLookup;
