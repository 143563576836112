/* eslint-disable max-len, no-unused-vars */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link, useNavigate } from 'react-router-dom';
import { pushDL } from '../../helpers';
import Button from '@beelineloans/cx-library/dist/components/buttons/Button';
import NumberField from '../FormatOnChangeNumberField';
import { ErrorMessage, H2 } from '@beelineloans/cx-library/dist/components/typography';
import { useSessionState } from '../../state/StateProvider';
import { URLS, ACTIONS } from '../../const';
import { checkError } from '../../helpers';
import Banner from '../../images/banner14.svg';
import InputAndButton from '../InputAndButton';

const NoLabelNumberField = styled(NumberField)`
  div span {
    top: 16px; // 51 - 35
    left: 25px;
  
    @media only screen and (${props => props.theme.mediaBreakpoints.tablet}) {
      top: 16px; // 51 - 35
    }
  
    @media only screen and (${props => props.theme.mediaBreakpoints.desktop}) {
      top: 14px; // 54 - 40
    }
  }
`;

const ButtonWrap = styled.div`
  text-align: center;
  font-weight: 500;
  a {
    width: auto !important;
  }
`;

const TipLink = styled.div`
  font-weight: 500;
  text-decoration: underline;
  margin-top: 100px;
  padding: 15px 0px;
  cursor: pointer;
`;

const TipContent = styled.div`
  font-weight: 500;
  margin-bottom: 35px;
  white-space: pre-line;

  display: block;
  overflow: hidden;
  max-height: 1000px;
  
  transition: opacity 1s;
  opacity: ${props => (props.show ? '1' : '0')};
  height: ${props => (props.show ? 'auto' : '0')};
`;

const Step = () => {
  const [{ profile, sourceId }, dispatch] = useSessionState();
  const [monthlyDebtAmount, setMonthlyDebtAmount] = useState(profile.monthlyDebt ?? 0);
  const [errors, setErrors] = useState({});
  const [fieldCompleted, setFieldCompleted] = useState(profile.monthlyDebt >= 0);
  const [showTipContent, setShowTipContent] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch({
      type: ACTIONS.PAGE,
      data: {
        image: sourceId ? null : Banner,
      },
      backButton: true,
      progress: 90,
      sourceId,
    });
  }, []);

  const handleInputChange = (field, value, type) => (event) => {
    let newValue = event.target.value;
    if (typeof newValue === 'undefined') newValue = event; // used for DropDownCustom component
    if (type === 'num' && newValue !== null) {
      let parsedNumber = (newValue || '').toString().replace(/,/g, '');
      // parsedNumber = Math.floor(parsedNumber);
      parsedNumber = parsedNumber === '' ? '' : Math.floor(parsedNumber);
      //if (!isNaN(parsedNumber) || parsedNumber === '') newValue = parsedNumber;
      if (!isNaN(parsedNumber)) newValue = parsedNumber;
      else newValue = undefined;
    }

    const newErrors = checkError(newValue, field, 'your current monthly debt payments', true, 0); // allow $0 monthly debt

    if (Object.entries(newErrors).length > 0) {
      setFieldCompleted(false);
      setErrors(newErrors);
    } else {
      dispatch({
        type: ACTIONS.STORE_PROFILE,
        data: { ...profile, [field]: newValue }
      });
      setMonthlyDebtAmount(newValue);
      setErrors({});
      setFieldCompleted(true);
    }
  };

  const showHideTipContent = (event) => {
    setShowTipContent(!showTipContent);
  };

  const onContinue = () => {
    setFieldCompleted(false); // prevent double click
    pushDL('submitForm', {
      FormBody: {
        monthlyDebtAmount // no current matching field in Hexagon
      }
    }, null);
  }

  const handleEnter = (event) => {
    if (event.which === 13 && fieldCompleted) {
      onContinue();
      navigate(URLS.APPLY_NINETEEN);
    }
  }

  return (
    <>
      <H2 bold>
        And what are your current monthly debt payments?
      </H2>
      <InputAndButton>
        <div>
          <NoLabelNumberField
            id="monthlyDebt"
            value={profile.monthlyDebt ?? 0}
            onInputChange={handleInputChange('monthlyDebt', null, 'num')}
            onKeyPress={handleEnter}
            border={false}
            rounded
            prefix="$"
            formatValue
            defaultValue={profile.monthlyDebt}
            placeholder="Enter amount"
            autoFocus
          />
          <ButtonWrap>
            <Button disabled={!fieldCompleted} onClick={onContinue} to={fieldCompleted ? URLS.APPLY_NINETEEN : null} linkComponent={Link}>Continue</Button>
          </ButtonWrap>
        </div>
        {errors.monthlyDebt && (
          <ErrorMessage>{errors.monthlyDebt}</ErrorMessage>
        )}
      </InputAndButton>
      <TipLink onClick={showHideTipContent}>What are monthly debt payments?</TipLink>
      <TipContent show={showTipContent}>
        {`Your monthly debt payments are the obvious ones like mortgage, car loan and personal loan and credit card repayments — but also includes monthly insurance and tax payments, plus alimony payments, child support or separate maintenance. Enter the monthly total of these to get your estimate more accurate.`}
      </TipContent>
    </>
  );
};

export default Step;