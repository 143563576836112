import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useSessionState } from '../../../state/StateProvider';
import { ACTIONS, URLS } from '../../../const';
import Keys from '../../../images/portal-keys.png';
import IntroContent from './IntroContent';
import IntroWrapper from './IntroWrapper';

const Styles = styled(IntroWrapper)`
  .intro-image {
    text-align: right;
    img {
      width: 100%;
      max-width: 188px;
      margin-top: -30px;

      @media only screen and (${props => props.theme.mediaBreakpoints.desktop}) {
        max-width: 188px;
      }
    }
  }
`;

const Intro2 = () => {
  const [, dispatch] = useSessionState();

  useEffect(() => {
    dispatch({
      type: ACTIONS.PAGE,
      data: {
        image: Keys,
        maxIntroImageWidth: 188,
        totalIntroCount: 4,
        currentIntroIndex: 1,
        nextUrl: URLS.INTRO_THREE,
        nextText: "Continue"
      },
      backButton: true
    });
  }, []);

  return (
    <Styles>
      <IntroContent title="And in under a minute, you'll know if we can help you get there." />
    </Styles>
  )
};

Intro2.propTypes = {
};

Intro2.defaultProps = {

};

export default Intro2;