import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useNavigate, useLocation } from 'react-router-dom';
import { pushDL } from '../../helpers';
import Button from '@beelineloans/cx-library/dist/components/buttons/Button';
import { H2 } from '@beelineloans/cx-library/dist/components/typography';
import { useSessionState } from '../../state/StateProvider';
import { URLS, ACTIONS } from '../../const';
import { buildPOSURL } from '@beelineloans/cx-library/dist/utils/helpers';
import Banner from '../../images/banner5.svg';

const Wrap = styled.div`
  display: flex;
  height: 100%;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
`;

const ButtonWrap = styled.div`
  text-align: center;
  font-weight: 500;
  width: 100%;
  button, a {
    margin: 0 auto;
    min-width: 200px;
    max-width: 325px;
    width: 100% !important;
  }
`;

const ApplyTransition = ({ step }) => {
  const location = useLocation();
  const [{ profile, sourceId }, dispatch] = useSessionState();
  const isRefi = profile.applicationType === 'refi';
  const isCashout = isRefi && profile.refinanceReason === 'CashOut';
  const navigate = useNavigate();

  useEffect(() => {
    dispatch({
      type: ACTIONS.PAGE,
      data: {
        image: sourceId ? null : Banner,
      },
      backButton: false,
      progress: undefined,
      sourceId,
    });
  }, [location.pathname]);

  const onContinue = () => {
    pushDL('submitForm', {}, null);
    if (step === 1) navigate(URLS.APPLY_TRANSITION_2);

    return;
  }

  let step1Copy = 'Let’s get this property pumping.';
  if (profile.isInvestment && !isRefi) step1Copy = 'The rent will be rolling in before you know it!';
  else if (!profile.isInvestment && !isRefi) step1Copy = 'You’ll be sending out housewarming invites in no time.';
  else if (isRefi && isCashout) step1Copy = 'You’ll have that cash in no time.';
  else if (isRefi && !isCashout) step1Copy = 'You’ll have that little life upgrade in no time.';

  return (
    <Wrap>
      <div>
        {step === 1 && (
          <>
            <H2 bold>
              Nice one!
            </H2>
            <H2 bold>
              {step1Copy}
            </H2>
          </>
        )}
        {step === 2 && (
          <>
            <H2 bold>
              Here’s how we’ll do it!
            </H2>
            <H2 bold>
              Create a Beeline profile by adding more info.
            </H2>
            <H2 bold>
              Then you’ll get more accurate loan options and know where you stand in minutes
            </H2>
          </>
        )}
      </div>
      <ButtonWrap>
        <Button onClick={onContinue} to={step === 2 ? buildPOSURL(URLS.PROFILE_STARTER + '/' + profile.profileId) : undefined}>
          {step === 2 ? 'Let’s get started!' : 'Continue'}
        </Button>
      </ButtonWrap>
    </Wrap>
  );
};

export default ApplyTransition;