/* eslint-disable max-len, no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { pushDL } from '../../helpers';
import RadioField from '../RadioField';
import { H2 } from '@beelineloans/cx-library/dist/components/typography';
import { useSessionState } from '../../state/StateProvider';
import { URLS, ACTIONS, mixedUseOptions } from '../../const';
import Banner from '../../images/banner13.svg';

const Radio = styled(RadioField)`
  input {
    max-width: 300px;
  }
`;

const TipLink = styled.div`
  font-weight: 500;
  text-decoration: underline;
  padding: 15px 0px;
  margin-top: 100px;
  cursor: pointer;
`;

const TipContent = styled.div`
  font-weight: 500;
  margin-bottom: 35px;
  white-space: pre-line;

  display: block;
  overflow: hidden;
  max-height: 1000px;

  transition: opacity 1s;
  opacity: ${props => (props.show ? '1' : '0')};
  height: ${props => (props.show ? 'auto' : '0')};
`;

const Step = () => {
  const [{ profile, sourceId }, dispatch] = useSessionState();
  const [showTipContent, setShowTipContent] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch({
      type: ACTIONS.PAGE,
      data: {
        image: sourceId ? null : Banner,
      },
      backButton: true,
      progress: 88,
      sourceId,
    });
  }, []);

  const handleInputChange = (field) => (event) => {
    let newValue = event.target.value;
    dispatch({
      type: ACTIONS.STORE_PROFILE,
      data: { ...profile, [field]: newValue === 'true' }
    });
    pushDL('submitForm', {
      FormBody: {
        isMixedProperty: newValue === 'true' ? 'yes' : 'no'
      }
    }, null);
    navigate(URLS.APPLY_EIGHTEEN);
  };

  const showHideTipContent = (event) => {
    setShowTipContent(!showTipContent);
  };

  return (
    <>
      <H2 bold>
        Is the property mixed use? (residential with at least one commercial unit)
      </H2>
      <Radio
        id="mixedUse"
        onInputChange={handleInputChange('mixedUse')}
        onClick={handleInputChange('mixedUse')}
        options={mixedUseOptions}
        selectedValue={profile.mixedUse}
        border={false}
      />
      <TipLink onClick={showHideTipContent}>What does this mean?</TipLink>
      <TipContent show={showTipContent}>
        {`Mixed use properties are those with at least one commercial unit in addition to residential units. If your property is mixed use, then we will point you to loan products best suited for that situation.`}
      </TipContent>
    </>
  );
};

export default Step;