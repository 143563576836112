import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FieldContainer } from '@beelineloans/cx-library/dist/components/forms/fields/FieldContainer';

const StyledInput = styled.input`
  display:            table;
  background:         ${props =>
    (props.checked ? props.theme.colours.background.navy : 'none')};
  border:             ${props =>
    `1px solid ${props.theme.colours.border.dark}`};
  border-radius:      30px;
  outline:            none;
  padding:            13px 25px;
  width:              auto;
  color:              ${props =>
    (props.checked
      ? props.theme.colours.text.light
      : props.theme.colours.text.dark)};
  appearance:         none;
  max-width:          100%
  height:             53px;
  overflow:           hidden;
  white-space:        nowrap;
  margin:             0 10px 10px 0;

  &:hover {
    background:       ${props => props.theme.colours.background.navy};
    color:            ${props => props.theme.colours.text.light};
    cursor:             pointer;
  }

  ::before {
    content:            "${props => props.text}";
    display:            block;
    text-align:         center;
  }
`;

const RadioField = ({
  onInputChange,
  id,
  autoComplete,
  label,
  ariaLabel,
  error,
  children,
  className,
  left,
  selectedValue,
  options,
  noHPad,
  ...rest
}) => {
  const handleChange = (event) => onInputChange(event);

  // Radios should have a label each, not one for all radios.

  return (
    <FieldContainer
      className={className}
      left={left}
      noHPad={noHPad}
      label={label}
      id={`${id}-group`}
      error={error}
      ariaLabel={ariaLabel}
      border={false}
    >
      {options.map((option) => (
        <StyledInput
          key={option.val}
          type="radio"
          aria-label={ariaLabel || label}
          autoComplete={autoComplete}
          onChange={handleChange}
          error={!!error}
          name={`${id}-group`}
          value={option.val}
          text={option.text}
          checked={selectedValue === option.val}
          {...rest}
        />
      ))}
      {children}
    </FieldContainer>
  );
};

RadioField.propTypes = {
  id: PropTypes.string.isRequired,
  autoComplete: PropTypes.string,
  error: PropTypes.string,
  onInputChange: PropTypes.func,
  label: PropTypes.node,
  ariaLabel: PropTypes.string,
  children: PropTypes.node,
  options: PropTypes.arrayOf(PropTypes.shape).isRequired,
  left: PropTypes.bool,
  noHPad: PropTypes.bool,
  selectedValue: PropTypes.string,
  className: PropTypes.string
};

RadioField.defaultProps = {
  autoComplete: 'off',
  left: true,
  noHPad: true,
  selectedValue: '',
  onInputChange: () => {}
};

export default RadioField;
