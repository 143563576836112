import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useSessionState } from '../../../state/StateProvider';
import { ACTIONS, URLS } from '../../../const';
import Letter from '../../../images/portal-letter.svg';
import IntroContent from './IntroContent';
import IntroWrapper from './IntroWrapper';

const Styles = styled(IntroWrapper)`
  .intro-image {
    text-align: center;
    img {
      width: 100%;
      max-width: 300px;

      @media only screen and (${props => props.theme.mediaBreakpoints.desktop}) {
        max-width: 400px;
      }
    }
  }
`;

const Intro3 = () => {
  const [, dispatch] = useSessionState();

  useEffect(() => {
    dispatch({
      type: ACTIONS.PAGE,
      data: {
        image: Letter,
        maxIntroImageWidth: 400,
        totalIntroCount: 4,
        currentIntroIndex: 2,
        nextUrl: URLS.INTRO_FOUR,
        nextText: "Continue"
      },
      backButton: true
    });
  }, []);

  return (
    <Styles>
      <IntroContent title="Then continue on to see your loan options and reliable approval." />
    </Styles>
  )
};

Intro3.propTypes = {
};

Intro3.defaultProps = {

};

export default Intro3;