import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { pushDL } from '../../helpers';
import RadioField from '../RadioField';
import { H2 } from '@beelineloans/cx-library/dist/components/typography';
import { useSessionState } from '../../state/StateProvider';
import { URLS, ACTIONS, unitsOptions } from '../../const';
import Banner from '../../images/banner7.svg';

const Radio = styled(RadioField)`
  input {
    max-width: 300px;
  }
`;

const Step = () => {
  const [{ profile, sourceId }, dispatch] = useSessionState();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch({
      type: ACTIONS.PAGE,
      data: {
        image: sourceId ? null : Banner,
      },
      backButton: true,
      progress: 35,
      sourceId,
    });
  }, []);

  const handleInputChange = (field) => (event) => {
    let newValue = event.target.value;
    dispatch({
      type: ACTIONS.STORE_PROFILE,
      data: { ...profile, [field]: newValue }
    });
    pushDL('submitForm', {
      FormBody: {
        units: newValue
      }
    }, null);
    navigate(URLS.APPLY_NINE);
  };

  return (
    <>
      <H2>
        How many units is your property?
      </H2>
      <Radio
        id="units"
        onClick={handleInputChange('units')}
        options={unitsOptions}
        selectedValue={profile.units}
        border={false}
      />
    </>
  );
};

export default Step;