import React, { useEffect, useRef } from 'react';
import { PropTypes } from 'prop-types';
import { useLocation } from 'react-router-dom';
import styled, { css } from 'styled-components';
import Container from '@beelineloans/cx-library/dist/components/layout/ContainerV2';
import Swirl from '@beelineloans/cx-library/dist/images/cream-swirl.png';
import { useSessionState } from '../../state/StateProvider';
import Spec from '../../images/spec.png';
import ProgressBar from '../ProgressBar';
import BannerDesktop from '../../images/banner-desk.svg';
import { cleanPathName } from '../../helpers';
import { URLS } from '../../const';

const Wrap = styled.section`
  display: flex;
  flex-direction: column;
  height: max(100vh, 100%);
	@media only screen and (${props => props.theme.mediaBreakpoints.desktop}) {
    flex-direction: row;
  }
`;

const ImageWrap = styled.div`
  display: ${props => ((props.$intro || props.$estimate) ? 'none' : 'initial')};
  width: 100%;
  z-index: 5;
  position: relative;
  background-image: url(${props => props.$backImage}), url(${Spec});
  background-repeat: no-repeat, repeat;
  background-size: 101%, 20%;
  background-position: center ${props => 50 + (0.5 * (props.$progress ?? 50))}%, left top;
  background-blend-mode: hard-light;
  height: ${props => props.$backImage ? 250 : 0}px;
  min-height: ${props => props.$backImage ? 250 : 0}px;
  margin-bottom: ${props => props.$backImage ? 0 : 30}px;
	transition: background-image 0.5s, background-size 0.5s, background-position 0.5s;

  @media only screen and (${props => props.theme.mediaBreakpoints.desktop}) {
    display: initial;
    width: 50%;
    height: 100vh;
    background-image: url(${BannerDesktop}), url(${Spec});
    background-repeat: no-repeat, repeat;
    background-size: 101%, 20%;
    background-position: center 80%;
    background-blend-mode: hard-light;
  }
`;

const Content = styled(Container)`
  width: 100%;
  text-align: center;
  overflow-y: auto;
  overflow-x: hidden;
  min-height: max(530px, calc(100vh - 280px));

  .container-inner {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: max(500px, calc(100vh - ${props => (props.$backImage ? '275px' : '0px')} - ${props => props.$progressBarExist ? 30 : 0}px));
    position: relative;
    padding-top: 40px;
    padding-left: 25px;
    padding-right: 25px;
    max-width: 530px;
    padding-bottom: 40px;
  }
  
  @media only screen and (${props => props.theme.mediaBreakpoints.desktop}) {
    width: 50%;
    margin-top: ${props => (props.$estimate ? '0' : '80')}px;
    min-height: calc(100vh - 84px);

    .container-inner {
      height: calc(100vh - ${props => (props.$estimate ? '0' : '80')}px);
      width: 430px;
      padding-left: 0;
      padding-right: 0;
    }
  }

  ${props =>
    props.$intro
    && css`
      overflow-y: hidden;
      overflow-x: hidden;
      margin-top: 80px;

      flex: 1 1 0%;
      text-align: center;
      background-image: url(${Swirl});
      background-position: center top;
      background-repeat: no-repeat;
      background-size: cover;
      background-color: transparent;

      .container-inner {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: max(500px, calc(100vh - 80px));
        max-width: 530px;
        width: 100%;
        padding-left: 25px;
        padding-right: 25px;
      }

      @media only screen and (${props => props.theme.mediaBreakpoints.desktop}) {
        width: 50% !important;
        flex-direction: column !important;
        padding-left: calc((50vw - 430px)/2) !important;
        padding-right: calc((50vw - 430px)/2) !important;
        position: relative !important;

        .container-inner {
          height:  max(500px, calc(100vh - 80px));
          max-width: 430px;
          padding: 30px 0 30px;
        }
      }
		`}

  ${props =>
    props.$estimate
    && css`
      overflow: hidden;
      height: 100%;
      width: 100%;

      .container-inner {
        height: max(100%, 100vh);
        width: 100%;
        padding: 0;
        max-width: 100%;
      }
    `}
`;

// const LogoLink = styled(ButtonText)`
//   position: absolute;
//   inset: 60px 28px;
// `;

// const Image = styled.picture`
//   img {
//     width: 94.5px;

//     @media only screen and (${props => props.theme.mediaBreakpoints.desktop}) {
//       width: 100px;
//     }
//   }
// `;

const Top = styled.div`height:1px; width:100%; opacity:0; position: relative; background: transparent;`;

const PageWrapper = ({ intro, estimate, children }) => {
  const location = useLocation();
  const wrapRef = useRef(null);
  // const queryParams = location.search ? queryString.parse(location.search) : {};
  // // These are included in some urls twice, might be getting added on from redirects
  // if (typeof queryParams.utm_source === 'object') queryParams.utm_source = queryParams.utm_source.toString();
  // const { utm_source } = queryParams;
  // const sourceId = (utm_source || '').toLowerCase();

  const [{ page, progress }] = useSessionState();

  useEffect(() => {
    const buyingAgain = location.pathname.includes(URLS.APPLY_ONE);
    const notDesktop = (window && window.innerWidth < 1280);
    if (wrapRef && !intro && !estimate && !buyingAgain && notDesktop) {
      const options = {
        behavior: 'smooth'
      }
      wrapRef.current.scrollIntoView(options);
    }
  }, [location.pathname]);

  return (
    <Wrap id={cleanPathName(location.pathname)}>
      <ImageWrap $backImage={page.image} $progress={progress} $intro={intro} $estimate={estimate} />
      {progress !== undefined && (
        <ProgressBar completed={progress} />
      )}
      <Content
        $backImage={page.image}
        $progressBarExist={progress !== undefined}
        $intro={intro}
        $estimate={estimate}
      >
        <Top ref={wrapRef} />
        {children}
      </Content>
    </Wrap>
  );
};

PageWrapper.propTypes = {
  children: PropTypes.any,
  intro: PropTypes.bool
};

PageWrapper.defaultProps = {
  intro: false
};

export default PageWrapper;