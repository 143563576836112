import React from 'react';
import { PropTypes } from 'prop-types';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

const IntroWrapper = ({ children, className }) => {
  return (
    <Wrapper className={className}>
      {children}
    </Wrapper>
  );
};

IntroWrapper.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
};

IntroWrapper.defaultProps = {
};

export default IntroWrapper;