/* eslint-disable max-len, no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { pushDL } from '../../helpers';
import RadioField from '../RadioField';
import { H2 } from '@beelineloans/cx-library/dist/components/typography';
import { useSessionState } from '../../state/StateProvider';
import { URLS, ACTIONS, isDSCROptions } from '../../const';
import Banner from '../../images/banner13.svg';

const Radio = styled(RadioField)`
  input {
    max-width: 300px;
  }
`;

const TipLink = styled.div`
  font-weight: 500;
  text-decoration: underline;
  padding: 15px 0px;
  margin-top: 100px;
  cursor: pointer;
`;

const TipContent = styled.div`
  font-weight: 500;
  margin-bottom: 35px;
  white-space: pre-line;

  display: block;
  overflow: hidden;
  max-height: 1000px;

  transition: opacity 1s;
  opacity: ${props => (props.show ? '1' : '0')};
  height: ${props => (props.show ? 'auto' : '0')};
`;

const Step = () => {
  const [{ profile, sourceId }, dispatch] = useSessionState();
  const [showTipContent, setShowTipContent] = useState(true);
  const oldValue = profile.isDSCR;
  const navigate = useNavigate();

  useEffect(() => {
    dispatch({
      type: ACTIONS.PAGE,
      data: {
        image: sourceId ? null : Banner,
      },
      backButton: true,
      progress: 87,
      sourceId,
    });
  }, []);

  const handleInputChange = (field) => (event) => {
    let newValue = event.target.value;
    let resetValues = {};
    if (oldValue !== undefined && `${oldValue}` !== newValue) {
      resetValues = {
        registerUnderLLC: null,
      }
    }
    dispatch({
      type: ACTIONS.STORE_PROFILE,
      data: {
        ...profile,
        [field]: newValue === 'true',
        ...resetValues
      }
    });
    pushDL('submitForm', {
      FormBody: {
        incomeSource: newValue === 'true' ? 'rentalIncome' : 'myIncome'
      }
    }, null);
    navigate(newValue === 'true' ? URLS.APPLY_SIXTEEN : (profile.propertyType === 'MultiFamily' ? URLS.APPLY_SEVENTEEN : URLS.APPLY_EIGHTEEN));
  };

  const showHideTipContent = (event) => {
    setShowTipContent(!showTipContent);
  };

  return (
    <>
      <H2 bold>
        Are you applying using the rental property’s income (DSCR loan) or your personal income?
      </H2>
      <Radio
        id="isDSCR"
        onClick={handleInputChange('isDSCR')}
        options={isDSCROptions}
        selectedValue={`${profile.isDSCR}`}
        border={false}
      />
      <TipLink onClick={showHideTipContent}>What's a DSCR loan?</TipLink>
      <TipContent show={showTipContent}>
        {`DSCR loans are assessed by verifying the property’s rental income, not the investor’s personal income.

        They allow to grow your portfolio beyond the number of properties your personal income can support using conventional loans.

        DCSR rates are slightly higher so if your income isn’t maxed out servicing other properties, it’s better to go for a conventional loan.

        To be eligible you need:
        
        - A credit score of at least 600.
        - Your total annual rent should cover your principal, interest, insurance and tax.
        - To have paid either mortgage payments or rent for at least 12 months.
        - LTV less than 80%.
        - Reserves to cover 6 months worth of payments.`}
      </TipContent>
    </>
  );
};

export default Step;