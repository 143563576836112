import React, { useState } from 'react';
import PropTypes from 'prop-types';
import InputField from '@beelineloans/cx-library/dist/components/forms/fields/InputField';
import { formatPercentage } from '@beelineloans/cx-library/dist/utils/formatters';

const FormatOnChangeNumberField = ({
  className,
  value,
  formatValue,
  formatToFloat,
  formatToPercentage,
  formatToPercentageExact,
  formatNumeric,
  percentageDecimals,
  onInputChange,
  defaultValue,
  ...rest
}) => {
  const format = (val) => {
    let formatted = (val ?? '').toString().replace(/,/g, '');
    if (formatted) {
      formatted = Math.floor(formatted);
      if (Number.isNaN(formatted)) formatted = 0;
    }

    return formatted.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  };

  const toNumeric = (val) => {
    let formatted = val.toString().replace(/,/g, '');
    if (formatted) {
      formatted = Math.floor(formatted);
      if (Number.isNaN(formatted)) formatted = 0;
    }
    return formatted.toString(); // .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  };

  const toFloat = (val) => {
    let formatted = val.toString().replace(/,/g, '');
    if (formatted) {
      formatted = parseFloat(formatted);
      if (Number.isNaN(formatted)) formatted = 0;
    }

    return formatted.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  };

  // we have a function that does this in the formatters helper under the utils dir... Not replacing as not sure what other projects use this option
  // added formatToPercentageExact to use the helper function

  const toPercentage = (val, decimals) => {
    let formatted = val.toString().replace(/,/g, '');
    if (formatted) {
      formatted = parseFloat(formatted);
      if (Number.isNaN(formatted)) formatted = 0;
    }

    return `${(val.toString().endsWith('%') ? formatted : (formatted * 100)).toFixed(decimals)}%`;
  };

  const [workingValue, setWorkingValue] = useState(
    formatToPercentageExact
      ? formatPercentage(Number.isNaN(value || defaultValue) ? 0 : Number.parseFloat(value || defaultValue) / 100, percentageDecimals, false)
      : formatToPercentage
        ? toPercentage(value || defaultValue, percentageDecimals)
        : (formatToFloat
          ? toFloat(value || defaultValue)
          : (formatValue
            ? format(value || defaultValue)
            : value || defaultValue))
  );

  const handleChange = (e) => {
    handleBlur(e);
    onInputChange(e);
  };

  const handleBlur = (e) => {
    let formatted = e.target.value.toString();

    if (formatToFloat) {
      formatted = toFloat(formatted);
    } else if (formatToPercentageExact) {
      formatted = formatPercentage(Number.isNaN(formatted) ? 0 : Number.parseFloat(formatted) / 100, percentageDecimals, false);
    } else if (formatToPercentage) {
      formatted = toPercentage(formatted, percentageDecimals);
    } else if (formatValue) {
      formatted = format(formatted);
    } else if (formatNumeric) {
      formatted = toNumeric(formatted);
    }

    setWorkingValue(formatted);
  };

  const handleFocus = () => {
    setWorkingValue(workingValue.toString().replace(/,/g, ''));
  };

  return (
    <InputField
      className={className}
      type="text"
      inputmode="numeric"
      onInputChange={handleChange}
      onBlur={handleBlur}
      onFocus={handleFocus}
      value={workingValue}
      defaultValue={defaultValue}
      {...rest}
    />
  );
};

FormatOnChangeNumberField.propTypes = {
  className: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onInputChange: PropTypes.func,
  formatValue: PropTypes.bool,
  formatToFloat: PropTypes.bool,
  formatToPercentage: PropTypes.bool,
  formatToPercentageExact: PropTypes.bool,
  formatNumeric: PropTypes.bool,
  percentageDecimals: PropTypes.number,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

FormatOnChangeNumberField.defaultProps = {
  formatValue: false,
  formatToFloat: false,
  formatToPercentage: false,
  formatToPercentageExact: false,
  formatNumeric: true,
  percentageDecimals: 2,
  onInputChange: () => { },
  defaultValue: ''
};

export default FormatOnChangeNumberField;
