import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { pushDL } from '../../helpers';
import Button from '@beelineloans/cx-library/dist/components/buttons/Button';
import InputField from '@beelineloans/cx-library/dist/components/forms/fields/InputField';
import { NumberField } from '@beelineloans/cx-library/dist/components/forms/fields';
// import { isEmailValid, mapStateToZip } from '@beelineloans/cx-library/dist/utils/helpers';
import { H2 } from '@beelineloans/cx-library/dist/components/typography';
import { postTry, getTry } from '@beelineloans/cx-library/dist/utils/fetch';
import { useSessionState } from '../../state/StateProvider';
import { URLS, ACTIONS, CONST } from '../../const';
import { checkWarning } from '../../helpers';
import Banner from '../../images/banner4.svg';

const ButtonWrap = styled.div`
  align-self: flex-end;
  font-weight: 500;
  a {
    width: auto !important;
  }
`;

const InLine = styled.div`
`;

const AddressField = styled(InputField)`
  display:inline-block;
  width: 66%;
  max-width: 400px;
  padding-right: 8%;
`;

const UnitNumberField = styled(InputField)`
  display:inline-block;
  width: 26%;
`;

const StateField = styled(InputField)`
  display:inline-block;
  width: 46%;
  max-width: 400px;
  padding-right: 8%;
`;

const ZipcodeField = styled(NumberField)`
  display:inline-block;
  width: 46%;
`;

const Step = () => {
  const [{ profile, sourceId }, dispatch] = useSessionState();
  // const [errors, setErrors] = useState({});
  const [fieldCompleted, setFieldCompleted] = useState(false);
  const [fullAddressInfo, setFullAddressInfo] = useState({ ...profile.address });
  const isPurchaseTBD = profile.applicationType === 'purchase' && !profile.signedPurchaseAgreement;

  useEffect(() => {
    dispatch({
      type: ACTIONS.PAGE,
      data: {
        image: sourceId ? null : Banner,
      },
      backButton: true,
      progress: 20,
      sourceId,
    });
  }, []);

  const isStateValid = (state) => {
    return true;
  };

  const isZipcodeValid = (zipcode) => {
    return zipcode >= 100 && zipcode <= 99999;
  };

  const validatePropertyAddr = (values) => {
    const newErrors = {};
    checkWarning(values.address, newErrors, 'address', 'address');
    checkWarning(values.city, newErrors, 'city', 'city');
    checkWarning(values.state, newErrors, 'state', 'state');
    checkWarning(values.zipcode, newErrors, 'zipcode', 'zipcode');
    if (!newErrors['state'] && !isStateValid(values.state)) newErrors['state'] = 'Please enter a valid state, e.g. NY';
    if (!newErrors['zipcode'] && !isZipcodeValid(values.zipcode)) newErrors['zipcode'] = 'Please enter a valid zipcode';
    return newErrors;
  }

  const toMapObj = (addr) => {
    return {
      street: addr.address,
      city: addr.city,
      state: addr.state,
      zipCode: addr.zipcode,
      country: 'US'
    };
  };

  const handleInputChange = (field, value, type) => async (event) => {
    let newValue = event.target.value?.trim();

    if (typeof newValue === 'undefined') newValue = event; // used for DropDownCustom component
    if (type === 'num' && newValue !== null) {
      const parsedNumber = Number.parseInt(newValue);
      if (!isNaN(parsedNumber)) newValue = parsedNumber;
      else newValue = undefined;
    }

    if (newValue !== '' && newValue !== undefined) {
      const newAddress = { ...fullAddressInfo, [field]: newValue };
      setFullAddressInfo(newAddress);
      const newErrors = validatePropertyAddr(newAddress);
      if (Object.entries(newErrors).length > 0) {
        setFieldCompleted(false);
        // setErrors(newErrors);
      } else {
        // all fields should have values now
        // 1 city can have multiple zipcodes, so first try matching using zipcode, then query using city could default to a zipcode in the state if there's no match
        const cityQueryResponse = await getTry(`${process.env.REACT_APP_WARMUPFACADE_API}/city/${newAddress.state}/${newAddress.zipcode}`);
        if (cityQueryResponse.city) {
          newAddress.city = cityQueryResponse.city; //  override user input
          setFullAddressInfo(newAddress);
        } else {
          const zipcodeQueryResponse = await getTry(`${process.env.REACT_APP_WARMUPFACADE_API}/zipcode/${newAddress.state}/${newAddress.city}`);
          if (zipcodeQueryResponse.zipcode) {
            newAddress.zipcode = zipcodeQueryResponse.zipcode;  // override user input
            setFullAddressInfo(newAddress);
          }
        }

        dispatch({
          type: ACTIONS.STORE_PROFILE,
          data: {
            ...profile,
            address: toMapObj(newAddress),
            isBroker: !CONST.BEELINE_SERVICE_STATES.includes(newAddress.state),
            unitNumber: newAddress.unitNumber
          }
        });
        // setErrors({});
        setFieldCompleted(true);

        // property check in Estated
        const propertyCheck = await postTry(`${process.env.REACT_APP_WARMUPFACADE_API}/property`, { address: toMapObj(newAddress) });
        dispatch({
          type: ACTIONS.PROPERTYCHECK,
          data: propertyCheck
        });
      }
    } else {
      setFieldCompleted(false);
    }
  };

  const onContinue = () => {
    pushDL('submitForm', {
      FormBody: {
        aptUnit: fullAddressInfo.unitNumber,
        address: fullAddressInfo.address,
        city: fullAddressInfo.city,
        state: fullAddressInfo.state,
        zipCode: fullAddressInfo.zipcode,
      }
    }, null);
  }

  return (
    <>
      <H2 bold>
        {profile.address?.state ? `We just need to know a little more about the address.` : `No worries, let’s try it this way.`}
      </H2>
      <InLine>
        <AddressField id="address" border={true} placeholder="Address" value={profile.address?.street} onInputChange={handleInputChange('address')} autoFocus />
        <UnitNumberField id="unitNumber" border={true} placeholder="Apt/Unit #" value={profile.unitNumber} onInputChange={handleInputChange('unitNumber')} />
      </InLine>
      <InputField id="city" border={true} placeholder="City" value={profile.address?.city} onInputChange={handleInputChange('city')} />
      <InLine>
        <StateField id="state" border={true} placeholder="State" value={profile.address?.state} onInputChange={handleInputChange('state')} />
        <ZipcodeField id="zipcode" border={true} formatNumeric placeholder="Zip code" value={profile.address?.zipCode} onInputChange={handleInputChange('zipcode')} />
      </InLine>
      <ButtonWrap>
        <Button disabled={!fieldCompleted}
          onClick={onContinue}
          to={fieldCompleted ?
            (isPurchaseTBD ? URLS.APPLY_FIVE : URLS.APPLY_SIX) : null}
          linkComponent={Link}>Continue</Button>
      </ButtonWrap>
    </>
  );
};

export default Step;