/* eslint-disable no-unused-vars */
import { CONST } from "./const";

const eligibilityCheck = (source, profile, mbpResponse) => {
  const isRefi = profile.applicationType === 'refi';
  const isInvestment = profile.propertyOccupancy === 'Investment';
  const isCashout = isRefi && profile.refinanceReason === 'CashOut';
  const isBroker = profile.isBroker;
  const isDSCR = profile.isDSCR;
  
  return false;
}

export default eligibilityCheck;
