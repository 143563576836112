import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { pushDL } from '../../helpers';
import Button from '@beelineloans/cx-library/dist/components/buttons/Button';
import { ButtonText } from '@beelineloans/cx-library/dist/components/buttons';
import { H2 } from '@beelineloans/cx-library/dist/components/typography';
import { postTry, getTry } from '@beelineloans/cx-library/dist/utils/fetch';
import { extractGoogleAddressPart } from '@beelineloans/cx-library/dist/utils/helpers';
import { useSessionState } from '../../state/StateProvider';
import { URLS, ACTIONS, CONST } from '../../const';
import Banner from '../../images/banner4.svg';
// import { checkError } from '../../helpers';
import AddressLookup from '../AddressLookup';

const ButtonWrap = styled.div`
  text-align: center;
  font-weight: 500;
  a {
    width: auto !important;
  }
`;

const InLine = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const Step = () => {
  const [{ profile, sourceId }, dispatch] = useSessionState();
  // const [errors, setErrors] = useState({});
  // const [warnings, setWarnings] = useState({});
  const [selectedGoogleAddress, setSelectedGoogleAddress] = useState({});
  const [unitNumber, setUnitNumber] = useState('');
  const isPurchaseWithSignedPAS = profile.applicationType === 'purchase' && profile.signedPurchaseAgreement;
  const isPurchaseTBD = profile.applicationType === 'purchase' && !profile.signedPurchaseAgreement;
  const isRefi = profile.applicationType === 'refi';

  const allAddressFieldsReady = (address) => {
    return (isPurchaseTBD ? true : address.street) && address.street && address.state && address.zipCode && address.country;
  };
  const [fieldCompleted, setFieldCompleted] = useState(profile.address && allAddressFieldsReady(profile.address));
  const [needManual, setNeedManual] = useState(false);

  useEffect(() => {
    dispatch({
      type: ACTIONS.PAGE,
      data: {
        image: sourceId ? null : Banner,
      },
      backButton: true,
      progress: 20,
      sourceId,
    });
  }, []);

  const toFormattedAddress = (address) => {
    return ((!isPurchaseTBD && address.street) ? (address.street + ', ') : '')
      + address.city + ', '
      + address.state + ' ' + address.zipCode + ', '
      + 'US';
  };

  const handleAddrInputChange = (field, value, type) => (event) => {
    let newValue = event.target.value;

    if (typeof newValue === 'undefined') newValue = event; // used for DropDownCustom component
    if (type === 'num' && newValue !== null) {
      const parsedNumber = Number.parseInt(newValue);
      if (!isNaN(parsedNumber)) newValue = parsedNumber;
      else newValue = undefined;
    }

    setFieldCompleted(false);
  };

  const handleUnitNumberChange = (field, value, type) => (unitNumber) => {
    dispatch({
      type: ACTIONS.STORE_PROFILE,
      data: { ...profile, unitNumber }
    });
    setUnitNumber(unitNumber);
  };

  const addressSelected = async (fullAddress) => {
    setSelectedGoogleAddress(fullAddress);
    const addressParts = fullAddress.address_components;
    if (addressParts) {
      const addressJson = {
        street: isPurchaseTBD ? '' : `${extractGoogleAddressPart(addressParts, 'street_number')} ${extractGoogleAddressPart(addressParts, 'route')}`.trim(),
        city: extractGoogleAddressPart(addressParts, 'locality')?.trim(),
        state: extractGoogleAddressPart(addressParts, 'administrative_area_level_1')?.trim(),
        // Place Autocomplete won't return `postal_code` if matching `locality`, e.g. prediction of 'New York, NY, USA', so should make zipCode optional when submitting
        // tokenise formatted_address is not reliable, e.g.
        // for TBD returns 'Gridiron, Houston, TX 77054, USA'
        // for P&S returns 'premise name, some street number and name, city, state zipcode, country'
        zipCode: extractGoogleAddressPart(addressParts, 'postal_code')?.trim(),
        country: 'US'
      };
      if (!addressJson.zipCode && addressJson.city && addressJson.state) {
        const zipcodeQueryResponse = await getTry(`${process.env.REACT_APP_WARMUPFACADE_API}/zipcode/${addressJson.state}/${addressJson.city}`);
        if (zipcodeQueryResponse.zipcode) {
          addressJson.zipCode = zipcodeQueryResponse.zipcode;
        }
      } else if (addressJson.zipCode && !addressJson.city && addressJson.state) {
        const cityQueryResponse = await getTry(`${process.env.REACT_APP_WARMUPFACADE_API}/city/${addressJson.state}/${addressJson.zipCode}`);
        if (cityQueryResponse.city) {
          addressJson.city = cityQueryResponse.city;
        }
      }

      dispatch({
        type: ACTIONS.STORE_PROFILE,
        data: {
          ...profile,
          address: addressJson,
          isBroker: !CONST.BEELINE_SERVICE_STATES.includes(addressJson.state)
        }
      });
      setFieldCompleted(true);

      if ((!isPurchaseTBD && !addressJson.street) || !addressJson.city || !addressJson.zipCode || !addressJson.state) {
        setNeedManual(true);
      }

      // property check in Estated
      if (!isPurchaseTBD && !needManual) {
        const propertyCheck = await postTry(`${process.env.REACT_APP_WARMUPFACADE_API}/property`, { address: addressJson });
        dispatch({
          type: ACTIONS.PROPERTYCHECK,
          data: propertyCheck
        });
      }
    }
  };

  const onContinue = () => {
    pushDL('submitForm', {
      FormBody: {
        apartmentSuiteUnit: unitNumber,
        googleAddress: {
          textResponse: selectedGoogleAddress.formatted_address,
          rawResponse: selectedGoogleAddress
        }
      }
    }, null);
  }

  return (
    <>
      {isPurchaseWithSignedPAS && (
        <H2 bold>
          What’s the address of the property you want to buy?
        </H2>
      )}
      {isPurchaseTBD && (
        <H2 bold>
          Nice. What area are you looking to buy?
        </H2>
      )}
      {isRefi && (
        <H2 bold>
          What’s the address of the property you want to refinance?
        </H2>
      )}
      <AddressLookup
        address={profile.address ? toFormattedAddress(profile.address) : ''}
        unitNumber={profile.unitNumber ?? ''}
        label={profile.address ? toFormattedAddress(profile.address) : ''}
        onAddrChange={handleAddrInputChange('address', null)}
        onUnitNumberChange={handleUnitNumberChange('unitNumber', null)}
        onSelect={addressSelected}
        placeType={isPurchaseTBD ? '(regions)' : 'address'}
      />
      <InLine>
        <ButtonText linkComponent={Link} to={isPurchaseTBD ? URLS.APPLY_FOUR_AREA_MANUAL : URLS.APPLY_FOUR_ADDR_MANUAL}>Can’t find the address?</ButtonText>
        <ButtonWrap>
          <Button disabled={!fieldCompleted}
            onClick={onContinue}
            to={fieldCompleted ?
              (needManual ?
                (isPurchaseTBD ? URLS.APPLY_FOUR_AREA_MANUAL : URLS.APPLY_FOUR_ADDR_MANUAL) :
                (isPurchaseTBD ? URLS.APPLY_FIVE : URLS.APPLY_SIX)) : null}
            linkComponent={Link}>Continue</Button>
        </ButtonWrap>
      </InLine>
    </>
  );
};

export default Step;