import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useSessionState } from '../../../state/StateProvider';
import { ACTIONS, URLS } from '../../../const';
import Welcome from '../../../images/portal-welcome-without-shadow.svg';
import IntroContent from './IntroContent';
import IntroWrapper from './IntroWrapper';

const Styles = styled(IntroWrapper)`
  .intro-image {
    position: absolute;
    margin-left: calc((100vw / 2) + 240px - 277px);
      top: 130px;

    @media only screen and (${props => props.theme.mediaBreakpoints.desktop}) {
      margin-left: calc((50vw / 2) + 215px - 277px);
    }
  }
`;

const ImageWrap = styled.div`
`;

const Image = styled.img`
`;

const Intro1 = () => {
  const [, dispatch] = useSessionState();

  useEffect(() => {
    dispatch({
      type: ACTIONS.PAGE,
      data: {
        image: null,
        totalIntroCount: 4,
        currentIntroIndex: 0,
        nextUrl: URLS.INTRO_TWO,
        nextText: "Continue"
      },
      backButton: true
    });
  }, []);

  return (
    <Styles>
      <ImageWrap className='intro-image'>
        <Image src={Welcome} width={277} />
      </ImageWrap>
      <IntroContent title="Okay, we’ll start by learning about your goals." />
    </Styles>
  );
};

Intro1.propTypes = {
};

Intro1.defaultProps = {

};

export default Intro1;