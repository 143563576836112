import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@beelineloans/cx-library/dist/components/buttons';
import { Theme } from '@beelineloans/cx-library/dist/theme/default';

const OptionsButton = ({ children, ...rest }) => (
  <Button
    bgColour="transparent"
    textColour={Theme.colours.text.dark}
    borderColour={Theme.colours.background.navy}
    hoverBgColour={Theme.colours.background.navy}
    hoverTextColour={Theme.colours.text.light}
    hoverBorderColour={Theme.colours.background.navy}
    {...rest}
  >
    {children}
  </Button>
);

OptionsButton.defaultProps = {
};

OptionsButton.propTypes = {
  children: PropTypes.node
};

export default OptionsButton;