import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link, useNavigate } from 'react-router-dom';
import { pushDL } from '../../helpers';
import Button from '@beelineloans/cx-library/dist/components/buttons/Button';
import { NumberField } from '@beelineloans/cx-library/dist/components/forms/fields';
import { ErrorMessage, H2 } from '@beelineloans/cx-library/dist/components/typography';
import { useSessionState } from '../../state/StateProvider';
import { URLS, ACTIONS } from '../../const';
import { checkError } from '../../helpers';
import Banner from '../../images/banner13.svg';
import InputAndButton from '../InputAndButton';

const ButtonWrap = styled.div`
  text-align: center;
  font-weight: 500;
  a {
    width: auto !important;
  }
`;

const Step = () => {
  const [{ profile, sourceId }, dispatch] = useSessionState();
  const [statedCreditScore, setStatedCreditScore] = useState(profile.creditScore ?? 0);
  const [errors, setErrors] = useState({});
  const [fieldCompleted, setFieldCompleted] = useState(profile.creditScore > 0);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch({
      type: ACTIONS.PAGE,
      data: {
        image: sourceId ? null : Banner,
      },
      backButton: true,
      progress: 95,
      sourceId,
    });
  }, []);

  const handleInputChange = (field, value, type) => (event) => {
    let newValue = event.target.value;

    if (typeof newValue === 'undefined') newValue = event; // used for DropDownCustom component
    if (type === 'num' && newValue !== null) {
      let parsedNumber = (newValue || '').toString().replace(/,/g, '');
      parsedNumber = Math.floor(parsedNumber);
      if (!isNaN(parsedNumber)) newValue = parsedNumber;
      else newValue = undefined;
    }

    const newErrors = checkError(newValue, field, 'a credit score', true);
    if (Object.entries(newErrors).length > 0) {
      setFieldCompleted(false);
      setErrors(newErrors);
    } else {
      dispatch({
        type: ACTIONS.STORE_PROFILE,
        data: { ...profile, [field]: newValue }
      });
      setStatedCreditScore(newValue);
      setErrors({});
      setFieldCompleted(true);
    }
  };

  const onContinue = () => {
    pushDL('submitForm', {
      FormBody: {
        statedCreditScore // no current matching field in Hexagon
      }
    }, null);
  }

  const handleEnter = (event) => {
    if (event.which === 13 && fieldCompleted) {
      onContinue();
      navigate(URLS.APPLY_QUOTE);
    }
  }

  return (
    <>
      <H2 bold>
        Finally, where do you sit credit score wise?
      </H2>

      <InputAndButton>
        <div>
          <NumberField
            id="creditScore"
            value={profile.creditScore ?? 0}
            onInputChange={handleInputChange('creditScore', null, 'num')}
            onKeyPress={handleEnter}
            border={false}
            rounded
            prefix=""
            placeholder="Enter credit score"
            autoFocus
          />
          <ButtonWrap>
            <Button disabled={!fieldCompleted} onClick={onContinue} to={fieldCompleted ? URLS.APPLY_QUOTE : null} linkComponent={Link}>Continue</Button>
          </ButtonWrap>
        </div>
        {errors.creditScore && (
          <ErrorMessage>{errors.downPayment}</ErrorMessage>
        )}
      </InputAndButton>
    </>
  );
};

export default Step;