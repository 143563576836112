import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Button from '../../OptionsButton';
import { ButtonText } from '@beelineloans/cx-library/dist/components/buttons';
import { H3 } from '@beelineloans/cx-library/dist/components/typography';
import { useSessionState } from '../../../state/StateProvider';
import ProgressDots from '../../ProgressDots';

const ImageWrap = styled.div`
`;

const Image = styled.img`
`;

const ButtonWrap = styled.div`
  text-align: center;
  font-weight: 500;
  a {
    width: 100%;
  }
`;

const ChatTextWrap = styled.div`
  text-align: center;
  font-weight: 500;
  margin-top: 10%;
`;

const Title = styled(H3)`
  margin-bottom: 50px;
  font-size:    24px !important;
  line-height:  32px;
`;

const IntroContent = ({ title }) => {
  const [{ page }] = useSessionState();

  const openChat = () => {
    if (window && window.$crisp) {
      window.$crisp.push(['do', 'chat:toggle']);
    }
  };

  return (
    <>
      <div>
        <Title>{title}</Title>
        {page.image && (
          <ImageWrap className='intro-image'>
            <Image src={page.image} width={page.maxIntroImageWidth} />
          </ImageWrap>
        )}
      </div>
      <div>
        {page.totalIntroCount && (  // exist && > 0
          <ProgressDots totalIntroCount={page.totalIntroCount} currentIntroIndex={page.currentIntroIndex} />
        )}
        {page.nextUrl && page.nextText && (
          <ButtonWrap>
            <Button to={page.nextUrl} linkComponent={page.nextUrl.indexOf('http') > -1 ? undefined : Link}>{page.nextText}</Button>
          </ButtonWrap>
        )}
        <ChatTextWrap>
          <span>Want to chat to a </span><ButtonText selected onClick={openChat}>Loan Guide?</ButtonText>
        </ChatTextWrap>
      </div>
    </>
  );
};

IntroContent.propTypes = {
  title: PropTypes.string
};

IntroContent.defaultProps = {
};

export default IntroContent;