const CONST = {
  MultiFamily: 'MultiFamily',
  SingleFamily: 'SingleFamily',
  NotSure: 'NotSure',
  PlannedUnitDevelopment: 'PlannedUnitDevelopment',
  Condo: 'Condo',
  CashOut: 'CashOut',
  Investment: 'Investment',
  SecondHome: 'Second Home',
  Yes: 'Yes',
  No: 'No',
  PROFILE_STATE: 'profile-redirection',
  MIN_CREDITSCORE: 620,
  BEELINE_SERVICE_STATES: [
    'AL', 'AR', 'AZ', 'CA', 'CO', 'DC', 'DE', 'FL', 'GA', 'IA',
    'IL', 'IN', 'KS', 'KY', 'LA', 'MA', 'MD', 'MI', 'MS', 'NC',
    'OH', 'OK', 'PA', 'RI', 'SC', 'TN', 'TX', 'VA', 'WI'],
  FastPassEligibility_LTV_lte: 0.75,  // lte: less than or equals to
  FastPassEligibility_DTI_lte: 0.4,
  FastPassEligibility_DSCR_gt: 1.0,   // gt: greater than
  FastPassEligibility_FICO_stated_gt: 720,

  warmupVersion: 20230221
};

const URLS = {
  INTRO: '/apply/intro',
  INTRO_ONE: '/apply/intro-one',
  INTRO_TWO: '/apply/intro-two',
  INTRO_THREE: '/apply/intro-three',
  INTRO_FOUR: '/apply/intro-four',
  APPLY: '/apply',
  APPLY_ONE: '/apply/buying-again',
  APPLY_TWO: '/apply/had-loan-before',
  APPLY_THREE: '/apply/have-signed-purchase',
  APPLY_FOUR: '/apply/area-looking',
  APPLY_FOUR_ADDR_MANUAL: '/apply/address-manual',
  APPLY_FOUR_AREA_MANUAL: '/apply/area-manual',
  // APPLY_FOUR_NOTLICENSEDSTATE: '/apply/not-licensed-state',
  // APPLY_FOUR_NOTLICENSEDSTATE_SUBMIT: '/apply/not-licensed-state-submit',
  APPLY_FIVE: '/apply/soon-to-buy',
  APPLY_SIX: '/apply/how-much-spend', // TBD, refi: home-current-worth, purchase: offer-amount
  APPLY_SIX_REMAININGAMOUNT: '/apply/remaining-amount',
  APPLY_SIX_WHYREFI: '/apply/why-refinance',
  APPLY_SIX_WHYREFI_CASHOUT: '/apply/how-much-take',
  APPLY_SEVEN: '/apply/property-type',
  APPLY_SEVEN_UNITSNUMBER: '/apply/home-units',
  APPLY_EIGHT: '/apply/pay-hoa-fees',
  APPLY_EIGHT_HOAAMOUNT: '/apply/hoa-fees-amount',
  APPLY_NINE: '/apply/plan-to-live',
  APPLY_NINE_RENTALINCOME: '/apply/rental-income',
  APPLY_TEN: '/apply/down-payment',
  APPLY_ELEVEN: '/apply/how-much-savings',
  APPLY_TWELVE: '/apply/properties-own-now',
  APPLY_THIRTEEN: '/apply/how-to-make-income',
  APPLY_FOURTEEN: '/apply/gross-monthly-income',
  APPLY_FIFTEEN: '/apply/income-source',
  APPLY_SIXTEEN: '/apply/who-is-buyer',
  APPLY_SEVENTEEN: '/apply/is-property-mixed',
  APPLY_EIGHTEEN: '/apply/how-much-monthly-debt',
  APPLY_NINETEEN: '/apply/credit-score',
  // APPLY_TWENTY: '/apply/your-contact',
  APPLY_QUOTE: '/apply/your-quote',
  // CONTINUE: '/continue',
  // BEST_PART: '/continue/best-part',
  // HERES_HOW: '/continue/heres-how',
  APPLY_TRANSITION_1: '/apply/transition-info-one',
  APPLY_TRANSITION_2: '/apply/transition-info-two',
  PROFILE_STARTER: '/profilestart',
  // PROFILE_BUILDPROFILE: '/apply/build-your-profile'
  PROFILE_LOGIN: '/login'
};

const ACTIONS = {
  // TODO all steps/pages of the warmup flow
  ALL: 'ALL',
  STORE_PROFILE: 'STORE_PROFILE',
  STORE_BORROWER: 'STORE_BORROWER',
  LOADING: 'LOADING',
  PAGE: 'PAGE',
  SIDE_NAV: 'SIDE_NAV',
  BACK_BUTTON: 'BACK_BUTTON',
  SKIPINTRO_BUTTON: 'SKIPINTRO_BUTTON',
  PROPERTYCHECK: 'PROPERTYCHECK',
};

const applicationTypeOptions = [
  { val: 'purchase', text: 'Buying' },
  { val: 'refi', text: 'Refinancing' },
];

const firstTimeHomeBuyerOptions = [
  { val: 'true', text: 'Yes' },
  { val: 'false', text: `No, I've bought before` },
];

const signedPurchaseAgreementOptions = [
  { val: 'true', text: 'Sure do!' },
  { val: 'false', text: 'No, not yet' },
];

const howSoonOptions = [
  { val: 'Now', text: 'Right away' },
  { val: '1-3m', text: '1 - 3 months' },
  { val: '3-6m', text: '3 - 6 months' },
  { val: '6+m', text: '6 months or more' },
  { val: 'Unknown', text: 'Not sure' }
];

const refinanceReasonOptions = [
  { val: 'ReduceTerm', text: 'Reduce loan term' },
  { val: CONST.CashOut, text: 'Take cash out' },
  { val: 'LowerRepayments', text: 'Lower payments' },
];

const propertyTypeOptions = [
  { val: 'SingleFamily', text: 'Single-family' },
  { val: CONST.MultiFamily, text: 'Multi-family' },
  { val: CONST.Condo, text: 'Condo' },
  { val: 'NotSure', text: 'Not sure' }
];

const unitsOptions = [
  { val: '2', text: '2 Units' },
  { val: '3', text: '3 Units' },
  { val: '4', text: '4 Units' },
  { val: '5+', text: '5+ Units' }
];

const payHoaFeesOptions = [
  { val: 'true', text: 'Yes' },
  { val: 'false', text: 'No' }
];

const propertyOccupancyOptions = [
  { val: 'Primary', text: 'Yes' },
  { val: CONST.Investment, text: `No, it's an investment` }
];

const propertyOccupancyOptionsWithSecondHome = [...propertyOccupancyOptions, { val: 'Second Home', text: `No, it's my second home` }]

const propertiesOwningOptions = [
  { val: '0', text: '0' },
  { val: '1', text: '1' },
  { val: '2', text: '2' },
  { val: '3', text: '3+' },
];

const employmentOptions = [
  { val: 'employed', text: 'Employed' },
  { val: 'self-employed', text: 'Self-employed' },
  { val: 'retired', text: 'Retired' },
  { val: 'notemployed', text: 'Not employed' }
];

const isDSCROptions = [
  { val: 'false', text: 'My income' },
  { val: 'true', text: 'Rental income' }
];

const registerUnderLLCOptions = [
  { val: 'false', text: 'My name' },
  { val: 'true', text: 'LLC' }
];

const mixedUseOptions = [
  { val: 'false', text: 'No' },
  { val: 'true', text: `Yes, it's mixed use` }
];

const creditRangeOptions = [
  { val: 760, text: 'Excellent (> 760)' },
  { val: 740, text: 'Very good (740 - 759)' },
  { val: 720, text: 'Good (720 - 739)' },
  { val: 700, text: 'Decent (700 - 719)' },
  { val: 680, text: 'Average (680 - 699)' },
  { val: 660, text: 'Fair (660 - 679)' },
  { val: 620, text: 'Low (620 - 659)' },
  { val: 580, text: 'Very Low (580 - 619)' },
  { val: 570, text: 'Poor (< 580)' }
];

export {
  CONST,
  URLS,
  ACTIONS,
  applicationTypeOptions,
  firstTimeHomeBuyerOptions,
  signedPurchaseAgreementOptions,
  howSoonOptions,
  refinanceReasonOptions,
  propertyTypeOptions,
  unitsOptions,
  payHoaFeesOptions,
  propertyOccupancyOptions,
  propertyOccupancyOptionsWithSecondHome,
  propertiesOwningOptions,
  employmentOptions,
  isDSCROptions,
  registerUnderLLCOptions,
  mixedUseOptions,
  creditRangeOptions,
};