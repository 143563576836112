/* eslint-disable max-len, no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { pushDL } from '../../helpers';
import RadioField from '../RadioField';
import { H2 } from '@beelineloans/cx-library/dist/components/typography';
import { useSessionState } from '../../state/StateProvider';
import { URLS, ACTIONS, registerUnderLLCOptions } from '../../const';
import Banner from '../../images/banner13.svg';

const Radio = styled(RadioField)`
  input {
    max-width: 300px;
  }
`;

const TipLink = styled.div`
  font-weight: 500;
  text-decoration: underline;
  margin-top: 100px;
  padding: 15px 0px;
  cursor: pointer;
`;

const TipContent = styled.div`
  font-weight: 500;
  margin-bottom: 35px;
  white-space: pre-line;

  display: block;
  overflow: hidden;
  max-height: 1000px;

  transition: opacity 1s;
  opacity: ${props => (props.show ? '1' : '0')};
  height: ${props => (props.show ? 'auto' : '0')};
`;

const Step = () => {
  const [{ profile, sourceId }, dispatch] = useSessionState();
  const [showTipContent, setShowTipContent] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch({
      type: ACTIONS.PAGE,
      data: {
        image: sourceId ? null : Banner,
      },
      backButton: true,
      progress: 87,
      sourceId,
    });
  }, []);

  const handleInputChange = (field) => (event) => {
    let newValue = event.target.value;
    dispatch({
      type: ACTIONS.STORE_PROFILE,
      data: { ...profile, [field]: newValue === 'true' }
    });
    pushDL('submitForm', {
      FormBody: {
        buyer: newValue === 'true' ? 'business' : 'buyer'
      }
    }, null);
    navigate(profile.propertyType === 'MultiFamily' ? URLS.APPLY_SEVENTEEN : URLS.APPLY_EIGHTEEN);
  };

  const showHideTipContent = (event) => {
    setShowTipContent(!showTipContent);
  };

  return (
    <>
      <H2 bold>
        Are you buying in your name or in an LLC?
      </H2>
      <Radio
        id="registerUnderLLC"
        onClick={handleInputChange('registerUnderLLC')}
        options={registerUnderLLCOptions}
        selectedValue={profile.registerUnderLLC}
        border={false}
      />
      <TipLink onClick={showHideTipContent}>What is the difference?</TipLink>
      <TipContent show={showTipContent}>
        {`If you need to close in a company name, certain loan products (like DSCR) may be better for you.  Typically property investors may be interested in this option.
        
          If you plan on closing and holding title in your name, then a conventional mortgage may be best.  This would apply to most people.`}
      </TipContent>
    </>
  );
};

export default Step;