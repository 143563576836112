/* eslint-disable no-restricted-globals */
import React from 'react';
import styled from 'styled-components';
import ArrowWhite from '@beelineloans/cx-library/dist/images/icons/down-arrow.png';
import ArrowDark from '@beelineloans/cx-library/dist/images/icons/arrow-down-dark.png';
import { Theme } from '@beelineloans/cx-library/dist/theme/default';
import { useSessionState } from '../state/StateProvider';

const ArrowImage = styled.img`
	position: absolute;
	top: 57.5px;
	left: 30px;
	width: 20px;
	height: 10px;
	cursor: pointer;
  z-index: 10;
  transform: rotate(90deg);

  @media only screen and (${props => props.theme.mediaBreakpoints.desktop}) {
    flex-direction: row;
	  left: calc(50vw + 90px);
  }
`;

const BackButton = () => {
  const [{ backButton, progress }] = useSessionState();
  const mobileBackImage = (progress > 0 || progress === undefined) ? ArrowWhite : ArrowDark;

  if (!backButton) return null;

  const backClick = () => {
    if (history) history.back();
  };

  return (
    <picture>
      <source media={`(${Theme.mediaBreakpoints.desktop})`} srcSet={ArrowDark} />
      <ArrowImage src={mobileBackImage} onClick={backClick} />
    </picture>
  );
};

BackButton.defaultProps = {
};

BackButton.propTypes = {
};

export default BackButton;