import { getFromSessionStorage, setToSessionStorage } from './browserStorage';
import { CONST } from '../const';

export const defaultState = {
  profile: {},
  borrower: {},
  page: {},
  loading: false,
  progress: 0,  // progress indicator, and background image zoom in
  sourceId: '',
  sideNav: false,
  backButton: false,
  propertyCheck: {},
};

const initialState = () => {
  const key = CONST.PROFILE_STATE;
  const priorState = getFromSessionStorage(key);

  if (!priorState) setToSessionStorage(key, defaultState);

  return priorState || defaultState;
};

export default initialState; 
