import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Navigate, Routes, Route, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import PreloadImages from './PreloadImages';
import Template from './components/ThemeSelectTemplate';
import Hamburger from './components/Hamburger';
import SkipIntro from './components/SkipIntro';
// import { NavOnlyHeader } from '@beelineloans/cx-library/dist/components/layout/headers';
import ScrollToTop from './components/ScrollToTop';
import Intro1 from './components/warmup/intro/Intro1.js';
import Intro2 from './components/warmup/intro/Intro2.js';
import Intro3 from './components/warmup/intro/Intro3.js';
import Intro4 from './components/warmup/intro/Intro4';
import BuyOrRefi from './components/warmup/BuyOrRefi';
import HadLoanBefore from './components/warmup/HadLoanBefore';
import HaveSignedPandS from './components/warmup/HaveSignedPandS';
import PropertyAddr from './components/warmup/PropertyAddr';
import PropertyAddrManual from './components/warmup/PropertyAddrManual';
import PropertyAreaManual from './components/warmup/PropertyAreaManual';
import BuyHowSoon from './components/warmup/BuyHowSoon';
import PropertyPrice from './components/warmup/PropertyPrice';
import RemainingAmount from './components/warmup/RemainingAmount';
import WhyRefi from './components/warmup/WhyRefi';
import CashoutAmount from './components/warmup/CashoutAmount';
import PropertyType from './components/warmup/PropertyType';
import Units from './components/warmup/HowManyUnits'
import PayHOA from './components/warmup/PayHOA';
import HOAAmount from './components/warmup/HOAAmount';
import PlanToLive from './components/warmup/PlanToLive';
import RentalIncome from './components/warmup/RentalIncome';
import DownPayment from './components/warmup/DownPayment';
import HowMuchSavings from './components/warmup/HowMuchSavings';
import PropertiesOwning from './components/warmup/PropertiesOwning';
import HowToMakeIncome from './components/warmup/HowToMakeIncome';
import MonthlyIncome from './components/warmup/MonthlyIncome';
import ApplyDSCR from './components/warmup/ApplyDSCR';
import DSCRUnderName from './components/warmup/DSCRUnderName';
import MixedUse from './components/warmup/MixedUse';
import CreditScore from './components/warmup/CreditScore';
import MonthlyDebt from './components/warmup/MonthlyDebt';
// import ContactDetails from './components/warmup/ContactDetails';
import Estimate from './components/warmup/Estimate';
import ApplyTransition from './components/warmup/ApplyTransition';
import { URLS } from './const';
import { StateProvider, useSessionState } from './state/StateProvider';
import MainWrapper from './components/wrappers/MainWrapper';
// import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { SideNav } from '@beelineloans/cx-library/dist/components/layout/nav';
import { ACTIONS } from './const';
import BackButton from './components/BackButton';
import { pushDL } from './helpers';

const Fade = styled.section`
.fadeIn {
  animation: 0.5s fadeIn forwards;
}

.fadeOut {
  animation: 0.5s fadeOut forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-100vh);
    z-index: 0;
  }
  to {
    opacity: 1;
    transform: translateY(0px);
    z-index: 0;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
    transform: translateY(0px, 0px);
    z-index: 0;
  }
  to {
    transform: translateY(100vh);
    z-index: 0;
    opacity: 0;
  }
}
`;

const Section = styled.section`
    position: relative;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 0;
    margin-bottom: 0;
    // height: 100%;
    justify-content: start;
    min-height: fit-content;
`;

// const TGroup = styled(TransitionGroup)`
//   height: 100%;
// `;
// const BasicTemplate = ({ ...rest }) =>
//   <Template {...rest} hideSignIn headerComponent={() => <NavOnlyHeader navigation={null} onNavClick={noop} />} />;

const EmptyTemplate = ({ ...rest }) => <Template {...rest} hideSignIn sideNavLinksComponent={false} headerComponent={null} referralBanner={true} />;

const RouteInner = () => {
  const location = useLocation();
  const [displayLocation, setDisplayLocation] = useState(location);
  const [transitionStage, setTransitionStage] = useState("fadeIn");
  const [{ backButton, skipintroButton }, dispatch] = useSessionState();

  const importAll = (r) => {
    const images = {};
    r.keys().forEach((item) => {
      images[item.replace('./', '')] = r(item);
    });
    return images;
  };

  const preloadImages = () => {
    const images = importAll(
      require.context('./images/', false, /\.(png|jpe?g)$/)
    );
    Object.values(images).forEach((image) => {
      const img = new Image();
      img.src = typeof image === 'string' ? image : image.default;
    });
  };

  useEffect(preloadImages, []);

  useEffect(() => {
    const backButtonAllowed = ![URLS.INTRO_ONE, URLS.APPLY_QUOTE].includes(location.pathname);
    if (backButtonAllowed && !backButton) {
      dispatch({
        type: ACTIONS.BACK_BUTTON,
        data: true
      })
    } else if (!backButtonAllowed && backButton) {
      dispatch({
        type: ACTIONS.BACK_BUTTON,
        data: false
      })
    }

    const skipintroButtonAllowed = [URLS.INTRO_ONE, URLS.INTRO_TWO, URLS.INTRO_THREE].includes(location.pathname);
    if (skipintroButtonAllowed && !skipintroButton) {
      dispatch({
        type: ACTIONS.SKIPINTRO_BUTTON,
        data: true
      })
    } else if (!skipintroButtonAllowed && skipintroButton) {
      dispatch({
        type: ACTIONS.SKIPINTRO_BUTTON,
        data: false
      })
    }
  }, [location.pathname]);

  useEffect(() => {
    if (location !== displayLocation) {
      setTransitionStage("fadeOut");
      if (location.pathname === URLS.APPLY_TRANSITION_1 || location.pathname === URLS.APPLY_QUOTE) {
        dispatch({
          type: ACTIONS.BACK_BUTTON,
          data: false
        });
      }
    }
  }, [location.pathname]);

  return (
    <ScrollToTop>
      <Fade id="fader">
        <MainWrapper>
          {/* <TGroup id="tgroup">
            <CSSTransition
              key={location.pathname}
              timeout={500}
              classNames="fade"
            > */}
          <Section
            id="route-wrap"
            className={`${transitionStage}`}
            onAnimationEnd={() => {
              if (transitionStage === "fadeOut") {
                setTransitionStage("fadeIn");
                setDisplayLocation(location.pathname);
              }
            }}>
            <Routes location={displayLocation}>
              <Route exact path={URLS.INTRO_ONE} element={<Intro1 />} />
              <Route exact path={URLS.INTRO_TWO} element={<Intro2 />} />
              <Route exact path={URLS.INTRO_THREE} element={<Intro3 />} />
              <Route exact path={URLS.INTRO_FOUR} element={<Intro4 />} />
              <Route exact path={URLS.APPLY_QUOTE} element={<Estimate />} />
              {/* <Route path={URLS.APPLY} element={<PageWrapper />}> */}
              <Route exact path={URLS.APPLY_ONE} element={<BuyOrRefi />} />
              <Route exact path={URLS.APPLY_TWO} element={<HadLoanBefore />} />
              <Route exact path={URLS.APPLY_THREE} element={<HaveSignedPandS />} />
              <Route exact path={URLS.APPLY_FOUR} element={<PropertyAddr />} />
              <Route exact path={URLS.APPLY_FOUR_ADDR_MANUAL} element={<PropertyAddrManual />} />
              <Route exact path={URLS.APPLY_FOUR_AREA_MANUAL} element={<PropertyAreaManual />} />
              <Route exact path={URLS.APPLY_FIVE} element={<BuyHowSoon />} />
              <Route exact path={URLS.APPLY_SIX} element={<PropertyPrice />} />
              <Route exact path={URLS.APPLY_SIX_REMAININGAMOUNT} element={<RemainingAmount />} />
              <Route exact path={URLS.APPLY_SIX_WHYREFI} element={<WhyRefi />} />
              <Route exact path={URLS.APPLY_SIX_WHYREFI_CASHOUT} element={<CashoutAmount />} />
              <Route exact path={URLS.APPLY_SEVEN} element={<PropertyType />} />
              <Route exact path={URLS.APPLY_SEVEN_UNITSNUMBER} element={<Units />} />
              <Route exact path={URLS.APPLY_EIGHT} element={<PayHOA />} />
              <Route exact path={URLS.APPLY_EIGHT_HOAAMOUNT} element={<HOAAmount />} />
              <Route exact path={URLS.APPLY_NINE} element={<PlanToLive />} />
              <Route exact path={URLS.APPLY_NINE_RENTALINCOME} element={<RentalIncome />} />
              <Route exact path={URLS.APPLY_TEN} element={<DownPayment />} />
              <Route exact path={URLS.APPLY_ELEVEN} element={<HowMuchSavings />} />
              <Route exact path={URLS.APPLY_TWELVE} element={<PropertiesOwning />} />
              <Route exact path={URLS.APPLY_THIRTEEN} element={<HowToMakeIncome />} />
              <Route exact path={URLS.APPLY_FOURTEEN} element={<MonthlyIncome />} />
              <Route exact path={URLS.APPLY_FIFTEEN} element={<ApplyDSCR />} />
              <Route exact path={URLS.APPLY_SIXTEEN} element={<DSCRUnderName />} />
              <Route exact path={URLS.APPLY_SEVENTEEN} element={<MixedUse />} />
              <Route exact path={URLS.APPLY_EIGHTEEN} element={<MonthlyDebt />} />
              <Route exact path={URLS.APPLY_NINETEEN} element={<CreditScore />} />
              <Route exact path={URLS.APPLY_TRANSITION_1} element={<ApplyTransition step={1} />} />
              <Route exact path={URLS.APPLY_TRANSITION_2} element={<ApplyTransition step={2} />} />
              {/* <Route exact path={URLS.APPLY_TWENTY} element={<ContactDetails />} /> */}
              {/* </Route> */}
              <Route path="*" element={<Navigate to={URLS.INTRO_ONE} />} />
            </Routes>
          </Section>
          {/* </CSSTransition>
          </TGroup> */}
        </MainWrapper>
      </Fade >
    </ScrollToTop >
  )
}

const Routing = () => {
  const [{ sideNav }, dispatch] = useSessionState();

  useEffect(() => {
    pushDL('warmUpV2', {}, {});
  }, []);

  const onSideNavClose = () => {
    dispatch({
      type: ACTIONS.SIDE_NAV,
      data: !sideNav
    })
  };

  return (
    <Router>
      <EmptyTemplate>
        <Hamburger />
        <SkipIntro />
        <BackButton />
        <SideNav onCloseClick={onSideNavClose} open={sideNav} leftToRight={false} showOnDesktop />
        <RouteInner />
      </EmptyTemplate >
    </Router>
  );
}

const App = () => {
  return (
    <StateProvider>
      <Routing />
      <PreloadImages />
    </StateProvider>
  );
}

export default App;
