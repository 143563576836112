/* eslint-disable no-unused-vars, no-console */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { pushDL } from '../../helpers';
import styled from 'styled-components';
import Container from '@beelineloans/cx-library/dist/components/layout/ContainerV2';
import LoadingCircle from '@beelineloans/cx-library/src/components/LoadingCircle';
import { getTry, postTry } from '@beelineloans/cx-library/dist/utils/fetch';
import { formatCurrency, formatPercentage } from '@beelineloans/cx-library/dist/utils/formatters';
import { roundDown } from '../../helpers';
import { URLS, ACTIONS, CONST } from '../../const';
import fastPassEligibilityCheck from '../../fastpass';
import { useSessionState } from '../../state/StateProvider';
import { Button, ButtonText } from '@beelineloans/cx-library/dist/components/buttons';
import { H2, Span } from '@beelineloans/cx-library/dist/components/typography';
import Confetti from '@beelineloans/cx-library/dist/images/confetti.png';
import PlantLeaves from '@beelineloans/cx-library/dist/images/plant-leaves.png';
import Box from '@beelineloans/cx-library/dist/images/box.png';
import BGImage from '../../images/banner.svg';
import Spec from '../../images/spec.png';
import Bird from '../../images/error.png';
import { buildPOSURL } from '@beelineloans/cx-library/dist/utils/helpers';

const WrapWithBG = styled(Container)`
  height: 100vh;
  width: 100%;
  .container-inner {
    padding-top: 0px;
    padding-bottom: 100px;
    height: 100vh;
    width: 100%;
    position: relative;
    background-image: url(${BGImage}), url(${Spec});
    background-repeat: no-repeat, repeat;
    background-size: 101%, 20%;
    background-position: center 90%, left top;
    background-blend-mode: hard-light;

    div {
      top: 50%;
      transform: translateY(-50%);
    }
}

  @media only screen and (min-width: 640px) {
    .container-inner {  
      background-image: none;
    }
  }
`;

const Wrap = styled(Container)`
  height: 100vh;
  width: 100%;
  text-align: center;
  overflow-x: hidden;
  overflow-y: scroll;

  .container-inner {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100vh;
    padding-left: 28px !important;
    padding-right: 28px !important;
    padding-top: 25px !important;
    padding-bottom: 35px !important;
    max-width: 530px;

    h2:first-child {
      margin-top: 65px;
    }
  }
  
  @media only screen and (${props => props.theme.mediaBreakpoints.desktop}) {
    margin-top: 0px;
    .container-inner {  
      height: 100vh;
      padding-left: 58px !important;
      max-width: 430px;

      h2:first-child {
        margin-top: 100px;
      }
    }
  }
`;

const WrapNoOverflow = styled(Wrap)`
  overflow: inherit !important;
`;

const Amount = styled(Span)`
	font-size: ${props => props.theme.size.desktop.h1} !important;
	line-height: ${props => props.theme.size.desktop.lineHeight.h1} !important;
	font-weight: 500;
  padding-bottom: 70px;
  display:block;

	.small-symbol {
		top: -10px;
	}
`;

const RotatedConfetti = styled.div`
  position: fixed;
  height: 183px;
  width: 125px;
  top: 130px;
  right: -28px;
  background-image: url(${Confetti});
  background-repeat: no-repeat;
  background-position: top 20% right 5%;
  background-size: 125px;
  transform: rotate(-38deg);

  @media only screen and (${props => props.theme.mediaBreakpoints.desktop}) {
    top: 210px;
    right: -26px;
    background-size: 125px;
  }
`;

const RotatedPlantLeaves = styled.div`
  position: fixed;
  height: 187px;
  width: 230px;
  top: 30%;
  right: -125px;
  background-image: url(${PlantLeaves});
  background-repeat: no-repeat;
  background-position: top 20% right 5%;
  background-size: 230px;
  transform: rotate(-100deg);

  @media only screen and (${props => props.theme.mediaBreakpoints.desktop}) {
    top: 38%;
    right: -130px;
  }
`;

const LowZIndexBox = styled.div`
  position: fixed;
  z-index: -1;
  height: 289px;
  width: 294px;
  top: 50%; // ${props => (310 + (props.$top ?? 0))}px;
  right: -45px;
  background-image: url(${Box});
  background-repeat: no-repeat;
  background-position: top 20% right 5%;
  background-size: 294px;
  transform: rotate(-0deg);

  @media only screen and (${props => props.theme.mediaBreakpoints.desktop}) {
    top: ${props => (440 + (props.$top ?? 0))}px;
    right: -45px;
  }
`;

const ButtonWrap = styled.div`
  text-align: center;
  font-weight: 500;
  a, button {
    width: 100%;
    min-width: 200px;
    max-width: 325px;
    text-align: center;
  }
`;

const ButtonWrapContinue = styled(ButtonWrap)`
  margin-bottom: 33px;
  margin-top: 5px;
`;

const H2WithTopGap = styled(H2)`
  padding-top: 40px;
`;

const H2WithBottomGap = styled(H2)`
  // padding-bottom: 262px;
`;

const H2WithSmallBottomGap = styled(H2)`
  padding-bottom: 104px;
`;

const Relative = styled.div`
  position: relative;
`;

const ErrorBird = styled.img`
  width: 100%;
  max-width: 300px;
  padding: 30px 0;
  margin: 0 auto;

  @media only screen and (${props => props.theme.mediaBreakpoints.desktop}) {
    max-width: 400px;
  }
`;
// const CheckValidationErrors = (values, isRefi) => {
//   const newErrors = isRefi ? validateProfileRefi(values) : validateProfilePurchase(values);
//   return newErrors;
// }

const Estimate = () => {
  const [loading, setLoading] = useState(true);
  // const navigate = useNavigate();
  const [{ profile, borrower, sourceId, propertyCheck }, dispatch] = useSessionState();
  const isRefi = profile.applicationType === 'refi';
  const isInvestment = profile.propertyOccupancy === 'Investment';
  const isCashout = isRefi && profile.refinanceReason === 'CashOut';
  const isBroker = profile.isBroker === true;
  const isDSCR = profile.isDSCR === true;
  const [errorMessage, setErrorMessage] = useState(null);
  const [program, setProgram] = useState(null);
  const [maxLoanAmt, setMaxLoanAmt] = useState(0.0);
  const [cashoutAmount, setCashoutAmount] = useState(0.0);
  const [maxPurchase, setMaxPurchase] = useState(null);
  const [loanTermYrsUsed, setLoanTermYrsUsed] = useState(0);
  const [eligibleFastPass, setEligibleFastPass] = useState(false);

  /*
  let mbpResponse = {
    "status": "OK",
    "result": {
      "maxLoanAmt": 647200,
      "id": 0,
      "programs": {
        "balanced": {
          "id": 118,
          "adjustedFees": "$1,272",
          "apr": "7.391",
          "closingCosts": "$1,272",
          "credit": "$0",
          "description": "Conf 30 Yr  Fixed",
          "feesCanNotShopFor": "$623",
          "feesCanShopFor": "$0",
          "feeList": [
            {
              "hudline": "",
              "description": "Origination Fee",
              "feeamount": 649,
              "prepaid": true,
              "section": "Origination Charges",
              "paymenttype": "Borrower-Paid At Closing",
              "feeamountFormatted": "$649"
            },
            {
              "hudline": "",
              "description": "Appraisal Fee",
              "feeamount": 525,
              "prepaid": false,
              "section": "Borrower May Not Shop For",
              "paymenttype": "Paid by Others",
              "feeamountFormatted": "$525"
            },
            {
              "hudline": "",
              "description": "Credit Report",
              "feeamount": 90,
              "prepaid": false,
              "section": "Borrower May Not Shop For",
              "paymenttype": "Borrower-Paid Before Closing",
              "feeamountFormatted": "$90"
            },
            {
              "hudline": "",
              "description": "Flood Certification",
              "feeamount": 8,
              "prepaid": true,
              "section": "Borrower May Not Shop For",
              "paymenttype": "Borrower-Paid At Closing",
              "feeamountFormatted": "$8"
            },
            {
              "hudline": "",
              "description": "Title",
              "feeamount": 0,
              "prepaid": "true",
              "section": "Borrower May Not Shop For",
              "paymenttype": "Borrower-Paid Before Closing",
              "feeamountFormatted": "$0"
            }
          ],
          "feesMinusOverpar": "$340",
          "hasFees": true,
          "monthlyPayment": "$2,763",
          "monthlyPaymentNum": 2762.43,
          "netPrice": "$100",
          "overParProfitAmount": "$932",
          "overParProfitPoint": 0.233,
          "point": 0,
          "pointDiscount": "$0",
          "rate": "7.374",
          "rules": {
            "showPoints": false,
            "showCredits": false
          },
          "totalFees": "$1,272",
          "totalFeesNum": 1272,
          "valid": true
        },
        "lowestMonthly": {},
        "lowestUpfront": {}
      },
      "status": "OK",
      "location": {
        "state": "AL",
        "city": "Montgomery",
        "zip": "36106"
      },
      "loanTermYrsUsed": 30
    }
  };
  */

  function removeEmpty(obj) {
    return Object.entries(obj)
      .filter(([_, v]) => v != null)
      .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {});
  }

  useEffect(() => {
    dispatch({
      type: ACTIONS.PAGE,
      data: {}, // remove progress bar
      sourceId,
      backButton: false,
    });
  }, []);

  useEffect(() => {
    let didCancel = false;

    const fetchData = async () => {
      let hasErrored = false;
      const formattedProfileObj = removeEmpty(profile);  // format before submitting
      // double check if zipcode exists
      const addressJson = { ...profile.address };
      if (!addressJson.zipCode && addressJson.city && addressJson.state) {
        const zipcodeQueryResponse = await getTry(`${process.env.REACT_APP_WARMUPFACADE_API}/zipcode/${addressJson.state}/${addressJson.city}`);
        if (zipcodeQueryResponse.zipcode) {
          addressJson.zipCode = zipcodeQueryResponse.zipcode;
          formattedProfileObj.address = addressJson;
        }
      } else if (addressJson.zipCode && !addressJson.city && addressJson.state) {
        const cityQueryResponse = await getTry(`${process.env.REACT_APP_WARMUPFACADE_API}/city/${addressJson.state}/${addressJson.zipCode}`);
        if (cityQueryResponse.city) {
          addressJson.city = cityQueryResponse.city;
          formattedProfileObj.address = addressJson;
        }
      }
      // save profile to server
      const saveResponse = await postTry(`${process.env.REACT_APP_WARMUPFACADE_API}/profile`, {
        profile: formattedProfileObj,
        borrower,
        propertyCheck,
      });

      if (!didCancel) {
        if (saveResponse.error)
          hasErrored = true;
        else
          hasErrored = (saveResponse.profileId === undefined);

        if (hasErrored) {
          setErrorMessage('Oops. Something went wrong.');
          pushDL('submitWarmup', {}, {
            warmupResult: 'Error',
            warmupVersion: CONST.warmupVersion
          });
        } else {
          const profileWithId = { ...profile, profileId: saveResponse.profileId };
          dispatch({
            type: ACTIONS.STORE_PROFILE,
            data: profileWithId
          });

          const mbpResponse = saveResponse.mbpResult;
          if (['SUCCESS', 'OK'].includes(mbpResponse.status)) {
            // use balanced plan, not lowestMonthly nor lowestUpfront
            const resultProgram = mbpResponse.programs?.balanced;
            const resultCashout = roundDown(Math.min(mbpResponse.cashoutLimit ?? 0, profile.cashOut ?? 0), 1000);
            setProgram(resultProgram);
            setMaxLoanAmt(mbpResponse.maxLoanAmt ?? 0);
            setCashoutAmount(resultCashout);
            setMaxPurchase((mbpResponse.maxLoanAmt ?? 0) + (profile.downPayment ?? 0));
            setLoanTermYrsUsed(mbpResponse.loanTermYrsUsed);

            setEligibleFastPass(fastPassEligibilityCheck(sourceId, profile, mbpResponse));

            let warmupResult;
            if (profile.creditScore >= CONST.MIN_CREDITSCORE && (resultProgram || isDSCR)) {
              if (!isRefi && !isBroker && !isDSCR) warmupResult = 'Congrats_Convential-Purchase';
              if (!isRefi && isDSCR) warmupResult = 'Congrats_DSCR-Purchase';
              if (!isRefi && !isDSCR && isBroker) warmupResult = 'Congrats_Broker-Convential-Purchase';
              if (isRefi && !isBroker && !isDSCR && isCashout && !isInvestment) {
                if (resultCashout >= profile.cashOut) {
                  warmupResult = 'Congrats_Convential-Refi-Cashout-OwnerOccupy';
                } else {
                  warmupResult = 'Sorry_CashoutAmountLessThanRequested';
                }
              }
              if (isRefi && !isBroker && !isDSCR && !isCashout && !isInvestment) warmupResult = 'Congrats_Convential-Refi-TermRate-OwnerOccupy';
              if (isRefi && isBroker) warmupResult = 'Congrats_Broker-Refi';
              if (isRefi && !isBroker && isDSCR) warmupResult = 'Congrats_DSCR-Refi';
              if (isRefi && !isBroker && !isDSCR && isInvestment) warmupResult = 'Congrats_Convential-Refi-Investment';
            } else if (profile.creditScore >= CONST.MIN_CREDITSCORE && !isDSCR && !resultProgram) {
              warmupResult = 'Sorry_NoProgram';
            } else { // if (profile.creditScore < CONST.MIN_CREDITSCORE)
              warmupResult = 'Sorry_LowCreditScore';
            }

            pushDL('submitWarmup', {}, {
              warmupResult,
              warmupVersion: CONST.warmupVersion
            });
          } else {
            pushDL('submitWarmup', {}, {
              warmupResult: 'Sorry_NoMBP',
              warmupVersion: CONST.warmupVersion
            });
          }
          // else status: "NO_RESULTS"
        }
        setLoading(false);
      }
    }

    fetchData()
      .catch(console.error);

    return () => { didCancel = true; };
  }, []);

  // 'start over' still keeps the profileId for Octopus to update existing lead, in case LGs have too many leads to handle/compete

  // Add new quotes
  const StartOver = (event) => {
    // 
  };

  // const clickContinue = () => {
  //   navigate(URLS.APPLY_TRANSITION_1);
  // };

  const Buttons = (
    <div>
      <ButtonWrapContinue>
        <Button to={buildPOSURL(URLS.PROFILE_STARTER + '/' + profile.profileId)}>Yep, continue!</Button>
      </ButtonWrapContinue>
      <ButtonWrap>
        <ButtonText selected to={URLS.APPLY_ONE} linkComponent={Link}>No, start again</ButtonText>
      </ButtonWrap>
    </div>
  );

  return (
    <>
      {loading && (<WrapWithBG><LoadingCircle /></WrapWithBG>)}
      {!loading && !!errorMessage && (
        <WrapNoOverflow>
          <H2 bold textAlign="center">
            Oops. Something went wrong.
          </H2>
          <ErrorBird src={Bird} />
          <ButtonWrap>
            <ButtonText selected to={URLS.APPLY_ONE} onClick={StartOver()} linkComponent={Link}>Try again</ButtonText>
          </ButtonWrap>
        </WrapNoOverflow>
      )}
      {!loading && !errorMessage && profile.creditScore >= CONST.MIN_CREDITSCORE && (program || isDSCR) && (
        <Wrap>
          <div>
            {!isRefi && !isBroker && !isDSCR && (
              <>
                <H2 bold>
                  Woohoo! Based on what we know, you can buy a property for up to
                </H2>
                <Amount>{formatCurrency(maxPurchase, 0, true)}</Amount>
                <RotatedConfetti />
                <H2 bold>
                  Now, let’s get you some loan options and a newly minted approval letter.
                </H2>
              </>
            )}
            {!isRefi && isDSCR && (
              <>
                <H2 bold>
                  Woohoo! Based on what we know so far, it looks like you’re eligible for a DSCR loan.
                </H2>
                <RotatedConfetti />
                <H2WithTopGap bold>
                  Now, let’s get you some accurate loan options with sweet rates.
                </H2WithTopGap>
              </>
            )}
            {!isRefi && !isDSCR && isBroker && (
              <>
                <H2 bold>
                  Woohoo! Based on what we know so far, it looks like you’re eligible for a Conventional loan.
                </H2>
                <RotatedConfetti />
                <H2WithTopGap bold>
                  Now, let’s get you some accurate loan options with sweet rates.
                </H2WithTopGap>
              </>
            )}
            {isRefi && !isBroker && !isDSCR && isCashout && !isInvestment && cashoutAmount >= profile.cashOut && (
              <>
                <H2 bold>
                  Woohoo! Based on what we know so far, you can cash out up to
                </H2>
                <Amount>{formatCurrency(cashoutAmount, 0, true)}</Amount>
                <RotatedConfetti />
                <H2 bold>
                  Now, let’s get you some accurate loan options with sweet rates.
                </H2>
              </>
            )}
            {isRefi && !isBroker && !isDSCR && isCashout && !isInvestment && cashoutAmount < profile.cashOut && (
              <Relative>
                <H2 bold>
                  Looks like you need one of our special, outside the box loan options.
                </H2>
                <H2WithBottomGap bold>
                  Please continue so a Loan Guide can reach out soon to find you the most interesting one.
                </H2WithBottomGap>
                <LowZIndexBox />
              </Relative>
            )}
            {isRefi && !isBroker && !isDSCR && !isCashout && !isInvestment && (
              <>
                <H2 bold>
                  Nice! Based on what we know so far, looks like you’re eligible to refi your property.
                </H2>
                <RotatedConfetti />
                <H2WithTopGap bold>
                  Now, let’s get you some accurate loan options with sweet rates.
                </H2WithTopGap>
              </>
            )}
            {isRefi && isBroker && (
              <Relative>
                <H2 bold>
                  Looks like you need one of our special, outside the box loan options.
                </H2>
                <H2WithBottomGap bold>
                  Please continue so a Loan Guide can reach out soon to find you the most interesting one.
                </H2WithBottomGap>
                <LowZIndexBox $top={95} />
              </Relative>
            )}
            {isRefi && !isBroker && isDSCR && (
              <>
                <H2 bold>
                  Woohoo! Based on what we know so far, it looks like you’re eligible for a DSCR loan.
                </H2>
                <RotatedConfetti />
                <H2WithTopGap bold>
                  Now, let’s get you those super accurate loan options with sweet rates.
                </H2WithTopGap>
              </>
            )}
            {isRefi && !isBroker && !isDSCR && isInvestment && (
              <>
                <H2 bold>
                  Nice! Based on what we know so far, looks like you’re eligible to refi your property.
                </H2>
                <RotatedConfetti />
                <H2WithTopGap bold>
                  Now, let’s get you some accurate loan options with sweet rates.
                </H2WithTopGap>
              </>
            )}
          </div>
          {Buttons}
        </Wrap>
      )}
      {!loading && !errorMessage && profile.creditScore >= CONST.MIN_CREDITSCORE && !isDSCR && !program && (
        <Wrap>
          <Relative>
            <H2 bold>
              Looks like you need one of our special, outside the box loan options.
            </H2>
            <H2WithBottomGap bold>
              Please continue so a Loan Guide can reach out soon to find you the most interesting one.
            </H2WithBottomGap>
            <LowZIndexBox />
          </Relative>
          {Buttons}
        </Wrap>
      )}
      {!loading && !errorMessage && profile.creditScore < CONST.MIN_CREDITSCORE && (
        <Wrap>
          <div>
            <H2 bold>
              It’s looking a little iffy.
            </H2>
            <H2WithSmallBottomGap bold>
              Our minimum FICO score requirement is typically {CONST.MIN_CREDITSCORE} — feel free to continue applying to give us a better idea of your full financial situation.
            </H2WithSmallBottomGap>
            <RotatedPlantLeaves />
          </div>
          {Buttons}
        </Wrap>
      )}
    </>
  );
};

export default Estimate;
