import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Theme } from '@beelineloans/cx-library/dist/theme/default';

const Wrap = styled.div`
  height: 4px;
  padding: 16px 10px 10px;
  position: relative;
  z-index: 10;
  background-color: ${props => props.theme.colours.background.cream};
  @media only screen and (${props => props.theme.mediaBreakpoints.desktop}) {
    width: 50vw;
    display: block;
    position: absolute;
    left: 50%;
    padding: 0;
  }
`;

const Bar = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${props => props.$bgColour};
  border-radius: 10px;
  @media only screen and (${props => props.theme.mediaBreakpoints.desktop}) {
    background-color: transparent;
    border-radius: 0;
  }
`

const Filler = styled.div`
  height: 100%;
  width: ${props => props.$completed}%;
  background-color: ${props => props.$completedColour};
  border-radius: inherit;
  text-align: right;
  transition: width 2s;
`;

const Label = styled.span`
  padding: 0;
  color: white;
  font-weight: bold
`;

const ProgressBar = ({ className, bgColour, completed, completedColour, showLabel }) => {
  return (
    <Wrap className={className}>
      <Bar $bgColour={bgColour}>
        <Filler $completed={completed} $completedColour={completedColour}>
          {showLabel && <Label>{`${completed}%`}</Label>}
        </Filler>
      </Bar>
    </Wrap>
  );
};

ProgressBar.defaultProps = {
  bgColour: 'rgba(255, 82, 107, 0.3)',  //TODO redLight: colourList.red 30%
  completed: 5,
  completedColour: Theme.colours.cta.default,
  showLabel: false
};

ProgressBar.propTypes = {
  bgColour: PropTypes.string,
  completed: PropTypes.number,
  completedColour: PropTypes.string,
  showLabel: PropTypes.bool,
  className: PropTypes.string
};

export default ProgressBar;