import { formatCurrency } from '@beelineloans/cx-library/dist/utils/formatters';

const checkError = (value, field, displayName, isNum = false, minNum, maxNum, valueToCurrency = true) => {
  const newErrors = {};
  checkWarning(value, newErrors, field, displayName, isNum, minNum, maxNum, valueToCurrency);
  return newErrors;
};

const allowZeroFields = ['propertyCurrentMortgageBalance', 'propertiesOwning', 'monthlyDebt'];

const checkWarning = (value, warnings, field, displayName, isNum = false, minNum, maxNum, valueToCurrency = true, isMobileNumber = false) => {
  if (!isNum && (!value || value.trim() === '')) {
    warnings[field] = `Please enter ${displayName}`;
  }

  if (isNum) {
    if (isNaN(value) || isNaN(parseFloat(value))) {
      warnings[field] = `Please enter ${displayName}`;
    } else {
      const parsedValue = parseFloat(value);
      if (parsedValue < 1 && !allowZeroFields.includes(field)) warnings[field] = `Please enter ${displayName}`;
      if (minNum !== undefined && !isMobileNumber && parsedValue < minNum)
        warnings[field] = `${displayName} is suggested to be greater than or equal to ${valueToCurrency ? formatCurrency(minNum) : minNum}`;
      if (maxNum !== undefined && !isMobileNumber && parsedValue > maxNum)
        warnings[field] = `${displayName} is suggested to be less than or equal to ${valueToCurrency ? formatCurrency(maxNum) : maxNum}`;

      if (minNum !== undefined && isMobileNumber && parsedValue < minNum) warnings[field] = `Please enter a valid ${displayName}`;
      if (maxNum !== undefined && isMobileNumber && parsedValue > maxNum) warnings[field] = `Please enter a valid ${displayName}`;

    }
  }
};

const getAddressDetails = (address_components, searchFor, attr = 'long_name') => {
  const result = address_components.find(a => a.types.indexOf(searchFor) > -1);
  if (!result) return undefined;
  return result[attr];
};

const noop = () => { };

const handleAddressSelected = (address, valuesSetter) => {
  let city = getAddressDetails(address.address_components, 'locality');
  if (!city) city = getAddressDetails(address.address_components, 'neighborhood')

  const zipCode = getAddressDetails(address.address_components, 'postal_code');
  const state = getAddressDetails(address.address_components, 'administrative_area_level_1', 'short_name');

  const updatedValue = {
    addressLine: `${getAddressDetails(address.address_components, 'street_number') ?? ''} ${getAddressDetails(address.address_components, 'route') ?? ''}`.trim(),
    city
  };

  if (zipCode) updatedValue.zipCode = zipCode;
  if (state) updatedValue.state = state;

  valuesSetter((prev) => ({ ...prev, ...updatedValue }));
};

const pushDL = (event, variables, data) => {
  window.dataLayer = window.dataLayer || [];
  const toPush = {
    event,
    ...variables,
  }
  if (data) {
    toPush.data = { ...data };
  }
  window.dataLayer.push(toPush);
};

const cleanPathName = (path) => (path ?? '').replace(/\//g, '_');

const roundDown = (value, roundDownToClosest) => Math.floor(value / roundDownToClosest) * roundDownToClosest;

export {
  cleanPathName,
  checkError,
  checkWarning,
  getAddressDetails,
  noop,
  handleAddressSelected,
  pushDL,
  roundDown
};
