import React, { useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { useLocation } from 'react-router-dom';
import PageWrapper from './PageWrapper';
import { URLS } from '../../const';
import { pushDL } from '../../helpers';

const MainWrapper = ({ children }) => {
  const location = useLocation();
  const isIntro = location.pathname.indexOf('/apply/intro') > -1;
  const isEstimate = location.pathname.indexOf(URLS.APPLY_QUOTE) > -1;

  useEffect(() => {
    pushDL('route-change', { route: location.pathname }, null);
  }, [location.pathname]);

  return <PageWrapper intro={isIntro} estimate={isEstimate}>{children}</PageWrapper>
};

MainWrapper.propTypes = {
  children: PropTypes.any
};

MainWrapper.defaultProps = {
};

export default MainWrapper;