import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useSessionState } from '../../../state/StateProvider';
import { ACTIONS, URLS } from '../../../const';
import LgChat from '../../../images/loan-guide-chat.png';
import IntroContent from './IntroContent';
import IntroWrapper from './IntroWrapper';

const Styles = styled(IntroWrapper)`
  .intro-image {
    text-align: center;
    img {
      width: 100%;
      max-width: 300px;

      @media only screen and (${props => props.theme.mediaBreakpoints.desktop}) {
        max-width: 400px;
      }
    }
  }
`;

const Intro4 = () => {
  const [, dispatch] = useSessionState();

  useEffect(() => {
    dispatch({
      type: ACTIONS.PAGE,
      data: {
        image: LgChat,
        maxIntroImageWidth: 400,
        totalIntroCount: 4,
        currentIntroIndex: 3,
        nextUrl: URLS.APPLY_ONE,
        nextText: "OK, let's go!"
      },
      backButton: true
    });
  }, []);

  return (
    <Styles>
      <IntroContent title="You’re never alone — chat to a Loan Guide if you need to." />
    </Styles>
  );
};

Intro4.propTypes = {
};

Intro4.defaultProps = {

};

export default Intro4;