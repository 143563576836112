import React from 'react';
import styled, { css } from 'styled-components';
import { useSessionState } from '../state/StateProvider';
import { ACTIONS } from '../const';
import HamburgerWhite from '@beelineloans/cx-library/dist/images/icons/hamburger-white.png';
import HamburgerDark from '@beelineloans/cx-library/dist/images/icons/hamburger-dark.png';
import { Theme } from '@beelineloans/cx-library/dist/theme/default';

const HamburgerImage = styled.img`
	position: absolute;
	top: 57.5px;
	right: 30px;
	width: 20px;
	height: 10px;
	cursor: pointer;
  z-index: 10;

  @media only screen and (${props => props.theme.mediaBreakpoints.desktop}) {
    flex-direction: row;
	  right: 50px;
  }

  @media only screen and (${props => props.theme.mediaBreakpoints.tabletMax}) {
    ${props =>
    props.isIntroPage
    && css`
        display: none;
      `}
  }
`;

const Hamburger = () => {
  const [{ progress, sideNav, page }, dispatch] = useSessionState();
  const mobileHamburgerImage = (progress > 0 || progress === undefined) ? HamburgerWhite : HamburgerDark;

  const hamburgerClick = () => {
    dispatch({
      type: ACTIONS.SIDE_NAV,
      data: !sideNav
    })
  };

  return (
    <picture>
      <source media={`(${Theme.mediaBreakpoints.desktop})`} srcSet={HamburgerDark} />
      <HamburgerImage id="hamburger" isIntroPage={page.currentIntroIndex !== undefined} src={mobileHamburgerImage} onClick={hamburgerClick} />
    </picture>
  );
};

Hamburger.defaultProps = {
};

Hamburger.propTypes = {
};

export default Hamburger;