import { ACTIONS, CONST } from '../const';
import { setToSessionStorage } from './browserStorage';

const stateReducer = (state, action) => {
  let newState;

  switch (action.type) {
    case ACTIONS.ALL:
      newState = {
        ...state,
        profile: action.profile,
        borrower: action.borrower,
        loading: action.loading,
        progress: action.progress,
        sourceId: action.sourceId,
        propertyCheck: action.propertyCheck,
      };
      break;
    case ACTIONS.PAGE:
      newState = {
        ...state,
        page: action.data,
        progress: action.progress,
        sourceId: action.sourceId,
        totalIntroCount: action.totalIntroCount,
        currentIntroIndex: action.currentIntroIndex,
        backButton: action.backButton
      };
      break;

    case ACTIONS.STORE_PROFILE:
      newState = {
        ...state,
        profile: action.data
      };
      break;

    case ACTIONS.STORE_BORROWER:
      newState = {
        ...state,
        borrower: action.data
      };
      break;

    case ACTIONS.LOADING:
      newState = {
        ...state,
        loading: action.data
      };
      break;

    case ACTIONS.SIDE_NAV:
      newState = {
        ...state,
        sideNav: action.data
      };
      break;

    case ACTIONS.BACK_BUTTON:
      newState = {
        ...state,
        backButton: action.data
      };
      break;

    case ACTIONS.SKIPINTRO_BUTTON:
      newState = {
        ...state,
        skipintroButton: action.data
      };
      break;

    case ACTIONS.PROPERTYCHECK:
      newState = {
        ...state,
        propertyCheck: action.data
      };
      break;

    default:
      newState = state;
      break;
  }

  setToSessionStorage(CONST.PROFILE_STATE, newState);
  return newState;
};

export default stateReducer;
