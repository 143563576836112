import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link, useNavigate } from 'react-router-dom';
import { pushDL } from '../../helpers';
import Button from '@beelineloans/cx-library/dist/components/buttons/Button';
import NumberField from '../FormatOnChangeNumberField';
import { ErrorMessage, H2 } from '@beelineloans/cx-library/dist/components/typography';
import { useSessionState } from '../../state/StateProvider';
import { URLS, ACTIONS } from '../../const';
import { checkError } from '../../helpers';
import Banner from '../../images/banner6.svg';
import InputAndButton from '../InputAndButton';

const NoLabelNumberField = styled(NumberField)`
  div span {
    top: 16px; // 51 - 35
    left: 25px;
  
    @media only screen and (${props => props.theme.mediaBreakpoints.tablet}) {
      top: 16px; // 51 - 35
    }
  
    @media only screen and (${props => props.theme.mediaBreakpoints.desktop}) {
      top: 14px; // 54 - 40
    }
  }
`;

const ButtonWrap = styled.div`
  text-align: center;
  font-weight: 500;
  a {
    width: auto !important;
  }
`;

const Step = () => {
  const [{ profile, sourceId }, dispatch] = useSessionState();
  const [remainingAmount, setRemainingAmount] = useState(profile.propertyCurrentMortgageBalance ?? 0);
  const [errors, setErrors] = useState({});
  const [fieldCompleted, setFieldCompleted] = useState(profile.propertyCurrentMortgageBalance >= 0);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch({
      type: ACTIONS.PAGE,
      data: {
        image: sourceId ? null : Banner,
      },
      backButton: true,
      progress: 30,
      sourceId,
    });
  }, []);

  const handleInputChange = (field, value, type) => (event) => {
    let newValue = event.target.value;

    if (typeof newValue === 'undefined') newValue = event; // used for DropDownCustom component
    if (type === 'num' && newValue !== null) {
      let parsedNumber = (newValue || '').toString().replace(/,/g, '');
      // parsedNumber = Math.floor(parsedNumber);
      parsedNumber = parsedNumber === '' ? '' : Math.floor(parsedNumber);
      //if (!isNaN(parsedNumber) || parsedNumber === '') newValue = parsedNumber;
      if (!isNaN(parsedNumber)) newValue = parsedNumber;
      else newValue = undefined;
    }

    const newErrors = checkError(newValue, field, 'the remaining amount', true, 0, profile.propertyValue);  // allow fully paid off loans
    if (Object.entries(newErrors).length > 0) {
      setFieldCompleted(false);
      setErrors(newErrors);
    } else {
      dispatch({
        type: ACTIONS.STORE_PROFILE,
        data: { ...profile, [field]: newValue }
      });
      setRemainingAmount(newValue);
      setErrors({});
      setFieldCompleted(true);
    }
  };

  const onContinue = () => {
    pushDL('submitForm', {
      FormBody: {
        remainingAmount
      }
    }, null);
  }

  const handleEnter = (event) => {
    if (event.which === 13 && fieldCompleted) {
      onContinue();
      navigate(URLS.APPLY_SIX_WHYREFI);
    }
  }

  return (
    <>
      <H2 bold>
        How much is left on your home loan?
      </H2>
      <InputAndButton>
        <div>
          <NoLabelNumberField
            id="propertyCurrentMortgageBalance"
            value={profile.propertyCurrentMortgageBalance ?? 0}
            onInputChange={handleInputChange('propertyCurrentMortgageBalance', null, 'num')}
            onKeyPress={handleEnter}
            border={false}
            rounded
            prefix="$"
            formatValue
            placeholder="Enter amount"
            defaultValue={profile.propertyCurrentMortgageBalance}
            autoFocus
          />
          <ButtonWrap>
            <Button disabled={!fieldCompleted} onClick={onContinue} to={fieldCompleted ? URLS.APPLY_SIX_WHYREFI : null} linkComponent={Link}>Continue</Button>
          </ButtonWrap>
        </div>
        {errors.propertyCurrentMortgageBalance && (
          <ErrorMessage>{errors.propertyCurrentMortgageBalance}</ErrorMessage>
        )}
      </InputAndButton>
    </>
  );
};

export default Step;