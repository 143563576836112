import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { pushDL } from '../../helpers';
import RadioField from '../RadioField';
import { H2 } from '@beelineloans/cx-library/dist/components/typography';
import { useSessionState } from '../../state/StateProvider';
import { URLS, ACTIONS, refinanceReasonOptions } from '../../const';
import Banner from '../../images/banner7.svg';

const Radio = styled(RadioField)`
  input {
    max-width: 300px;
  }
`;

const Step = () => {
  const [{ profile, sourceId }, dispatch] = useSessionState();
  const oldValue = profile.refinanceReason;
  const navigate = useNavigate();

  useEffect(() => {
    dispatch({
      type: ACTIONS.PAGE,
      data: {
        image: sourceId ? null : Banner,
      },
      backButton: true,
      progress: 35,
      sourceId,
    });
  }, []);

  const handleInputChange = (field) => (event) => {
    let newValue = event.target.value;
    let resetValues = {};
    if (oldValue !== undefined && oldValue !== newValue) {
      resetValues = {
        cashOut: null,
      }
    }
    dispatch({
      type: ACTIONS.STORE_PROFILE,
      data: {
        ...profile,
        [field]: newValue,
        ...resetValues
      }
    });
    pushDL('submitForm', {
      FormBody: {
        whyRefi: newValue
      }
    }, null);
    navigate(newValue === 'CashOut' ? URLS.APPLY_SIX_WHYREFI_CASHOUT : URLS.APPLY_SEVEN);
  };

  return (
    <>
      <H2 bold>
        So, tell us why you want to refinance.
      </H2>
      <Radio
        id="refinanceReason"
        onClick={handleInputChange('refinanceReason')}
        options={refinanceReasonOptions}
        selectedValue={profile.refinanceReason}
        border={false}
      />
    </>
  );
};

export default Step;