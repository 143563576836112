import React from 'react';
import styled from 'styled-components';
import BannerDesk from './images/banner-desk.svg';
import Banner from './images/banner.svg';
import Banner1 from './images/banner1.svg';
import Banner2 from './images/banner2.svg';
import Banner3 from './images/banner3.svg';
import Banner4 from './images/banner4.svg';
import Banner5 from './images/banner5.svg';
import Banner6 from './images/banner6.svg';
import Banner7 from './images/banner7.svg';
import Banner8 from './images/banner8.svg';
import Banner9 from './images/banner9.svg';
import Banner10 from './images/banner10.svg';
import Banner11 from './images/banner11.svg';
import Banner12 from './images/banner12.svg';
import Banner13 from './images/banner13.svg';
import Banner14 from './images/banner14.svg';
import Banner15 from './images/banner15.svg';
import SubmitApp from './images/submit-animation.svg';
import PortalLetter from './images/portal-letter.svg';
import PortalWelcome from './images/portal-welcome-without-shadow.svg';
import SkipIntro from './images/skipintro.svg';

const Hide = styled.div`
  display: none;
`;

// Find a better method
const PreloadImages = () => (
  <Hide>
    <img src={Banner} width="0" height="0" alt="" />
    <img src={BannerDesk} width="0" height="0" alt="" />
    <img src={Banner1} width="0" height="0" alt="" />
    <img src={Banner2} width="0" height="0" alt="" />
    <img src={Banner3} width="0" height="0" alt="" />
    <img src={Banner4} width="0" height="0" alt="" />
    <img src={Banner5} width="0" height="0" alt="" />
    <img src={Banner6} width="0" height="0" alt="" />
    <img src={Banner7} width="0" height="0" alt="" />
    <img src={Banner8} width="0" height="0" alt="" />
    <img src={Banner9} width="0" height="0" alt="" />
    <img src={Banner10} width="0" height="0" alt="" />
    <img src={Banner11} width="0" height="0" alt="" />
    <img src={Banner12} width="0" height="0" alt="" />
    <img src={Banner13} width="0" height="0" alt="" />
    <img src={Banner14} width="0" height="0" alt="" />
    <img src={Banner15} width="0" height="0" alt="" />
    <img src={SubmitApp} width="0" height="0" alt="" />
    <img src={PortalLetter} width="0" height="0" alt="" />
    <img src={PortalWelcome} width="0" height="0" alt="" />
    <img src={SkipIntro} width="0" height="0" alt="" />
  </Hide>
);

export default React.memo(PreloadImages);