import React from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeProvider } from 'styled-components';
import { Footer } from '@beelineloans/cx-library/dist/components/layout/footers/Footer';
import { Theme } from '@beelineloans/cx-library/dist/theme/default';
import GlobalStyle from '@beelineloans/cx-library/dist/theme/global-styles';
import { useSessionState } from '../state/StateProvider';
import { ACTIONS } from '../const';

const Main = styled.main`
  overflow: hidden;
  z-index: 1;
  position: relative;
  font-size: 15px;

  input {
    font-size: 15px;
  }

  button, a[role=button] {
    padding-top: 15.5px;
    padding-bottom: 15.5px;
    line-height: 20px;

    @media only screen and (${props => props.theme.mediaBreakpoints.desktop}) {
      padding-top: 13px;
      padding-bottom: 13px;
    }
  }

  ${props => props.$cssOverride}
`;

const ThemeSelectTemplate = ({
  children,
  hideFooter,
  footerComponent: FooterComponent,
  provider: Provider,
  cssOverride
}) => {
  const [, dispatch] = useSessionState();

  const onMainClick = (e) => {
    if (window) {
      if (window.innerWidth >= 1280) { // desktop
        // side menu is always half the screen, so no need for refs, just check click location
        const half = window.innerWidth / 2;
        if (e.target.id?.indexOf('hamburger') === -1 && e.pageX < half) {
          dispatch({
            type: ACTIONS.SIDE_NAV,
            data: false
          });
        }

      }
    }
  }

  return (
    <ThemeProvider theme={Theme}>
      <GlobalStyle />
      <Main $cssOverride={cssOverride} onClick={onMainClick}>
        {Provider ? <Provider>{children}</Provider> : children}
      </Main>
      {!hideFooter && FooterComponent && <FooterComponent />}
    </ThemeProvider>
  );
};

ThemeSelectTemplate.defaultProps = {
  children: null,
  hideFooter: false,
  footerComponent: Footer,
  provider: null
};

ThemeSelectTemplate.propTypes = {
  children: PropTypes.node,
  hideFooter: PropTypes.bool,
  footerComponent: PropTypes.any,
  provider: PropTypes.any,
  cssOverride: PropTypes.string
};

export default ThemeSelectTemplate;
