import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useSessionState } from '../state/StateProvider';
import SkipIntroSVG from '../images/skipintro.svg';
import { Theme } from '@beelineloans/cx-library/dist/theme/default';
import { URLS } from '../const';

const SkipIntroImage = styled.img`
  position: absolute;
  top: 57.5px;
  right: 30px;
  cursor: pointer;
  z-index: 10;

  @media only screen and (${props => props.theme.mediaBreakpoints.desktop}) {
    flex-direction: row;
    right: 90px;
  }
`;

const SkipIntro = () => {
  const navigate = useNavigate();

  const [{ skipintroButton }] = useSessionState();
  if (!skipintroButton) return null;


  const skipintroClick = () => {
    navigate(`${URLS.APPLY_ONE}`);
  };

  return (
    <picture>
      <source media={`(${Theme.mediaBreakpoints.desktop})`} srcSet={SkipIntroSVG} />
      <SkipIntroImage src={SkipIntroSVG} onClick={skipintroClick} />
    </picture>);
};

SkipIntro.defaultProps = {
};

SkipIntro.propTypes = {
};

export default SkipIntro;