import React from 'react';
import { createRoot } from 'react-dom/client';
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import App from './App';
import reportWebVitals from './reportWebVitals';

Sentry.init({
  dsn: "https://29d70c491974447c943418157433d2aa@o429242.ingest.sentry.io/4504721024417792",
  integrations: [new BrowserTracing()],
  environment: "prod",

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});
Sentry.configureScope(function (scope) {
  if (window && document) {
    scope.setTag("page.pathname", document.location.pathname);
    scope.setTag("page.hostname", document.location.hostname);
    scope.setContext("page.datalayer", { "dataLayer": JSON.stringify(window.dataLayer) });
  }
});

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();