/* eslint-disable max-len, no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { pushDL } from '../../helpers';
import RadioField from '../RadioField';
import { H2 } from '@beelineloans/cx-library/dist/components/typography';
import { useSessionState } from '../../state/StateProvider';
import { URLS, ACTIONS, payHoaFeesOptions } from '../../const';
import Banner from '../../images/banner7.svg';

const Radio = styled(RadioField)`
  input {
    max-width: 300px;
  }
`;

const TipLink = styled.div`
  font-weight: 500;
  text-decoration: underline;
  padding: 15px 0px;
  margin-top: 100px;
  cursor: pointer;
`;

const TipContent = styled.div`
  font-weight: 500;
  margin-bottom: 35px;
  white-space: pre-line;

  display: block;
  overflow: hidden;
  max-height: 1000px;

  transition: opacity 1s;
  opacity: ${props => (props.show ? '1' : '0')};
  height: ${props => (props.show ? 'auto' : '0')};
`;

const Step = () => {
  const [{ profile, sourceId }, dispatch] = useSessionState();
  const [showTipContent, setShowTipContent] = useState(true);
  const oldValue = profile.payHoaFees;
  const navigate = useNavigate();

  useEffect(() => {
    dispatch({
      type: ACTIONS.PAGE,
      data: {
        image: sourceId ? null : Banner,
      },
      backButton: true,
      progress: 35,
      sourceId,
    });
  }, []);

  const handleInputChange = (field) => (event) => {
    let newValue = event.target.value;
    let resetValues = {};
    if (oldValue !== undefined && `${oldValue}` !== newValue) {
      resetValues = {
        propertyMonthlyHoa: null,
      }
    }
    dispatch({
      type: ACTIONS.STORE_PROFILE,
      data: {
        ...profile,
        [field]: newValue === 'true',
        ...resetValues
      }
    });
    pushDL('submitForm', {
      FormBody: {
        payHoaFees: newValue === 'true' ? 'yes' : 'no'
      }
    }, null);
    navigate(newValue === 'true' ? URLS.APPLY_EIGHT_HOAAMOUNT : URLS.APPLY_NINE);
  };

  const showHideTipContent = (event) => {
    setShowTipContent(!showTipContent);
  };

  return (
    <>
      <H2 bold>
        And will you pay home owners association (HOA) fees or neighborhood dues for this property?
      </H2>
      <Radio
        id="payHoaFees"
        onClick={handleInputChange('payHoaFees')}
        options={payHoaFeesOptions}
        selectedValue={`${profile.payHoaFees}`}
        border={false}
      />
      <TipLink onClick={showHideTipContent}>What are HOA fees?</TipLink>
      <TipContent show={showTipContent}>
        {`HOA or neighborhood fees are paid monthly to the Homeowners Association to cover things like amenties, property maintenance and repairs on common property — so property and areas that are shared by a bunch of property owners.`}
      </TipContent>
    </>
  );
};

export default Step;