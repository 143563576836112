import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link, useNavigate } from 'react-router-dom';
import { pushDL } from '../../helpers';
import Button from '@beelineloans/cx-library/dist/components/buttons/Button';
import { ErrorMessage } from '@beelineloans/cx-library/dist/components/typography';
import NumberField from '../FormatOnChangeNumberField';
import { H2 } from '@beelineloans/cx-library/dist/components/typography';
import { formatCurrency } from '@beelineloans/cx-library/dist/utils/formatters';
import { useSessionState } from '../../state/StateProvider';
import { URLS, ACTIONS } from '../../const';
import { checkError } from '../../helpers';
import Banner from '../../images/banner7.svg';
import InputAndButton from '../InputAndButton';

const NoLabelNumberField = styled(NumberField)`
  div span {
    top: 16px; // 51 - 35
    left: 25px;
  
    @media only screen and (${props => props.theme.mediaBreakpoints.tablet}) {
      top: 16px; // 51 - 35
    }
  
    @media only screen and (${props => props.theme.mediaBreakpoints.desktop}) {
      top: 14px; // 54 - 40
    }
  }
`;

const ButtonWrap = styled.div`
  text-align: center;
  font-weight: 500;
  a {
    width: auto !important;
  }
`;

const Step = () => {
  const [{ profile, sourceId }, dispatch] = useSessionState();
  const [errors, setErrors] = useState({});
  const [fieldCompleted, setFieldCompleted] = useState(profile.cashOut > 0);
  const [cashOut, setCashOut] = useState(profile.cashOut ?? 0);
  const [maxCashOut, setMaxCashOut] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch({
      type: ACTIONS.PAGE,
      data: {
        image: sourceId ? null : Banner,
      },
      backButton: true,
      progress: 35,
      sourceId,
    });

    setMaxCashOut(calculateMaxCashOut(profile.propertyCurrentMortgageBalance, profile.propertyValue));
  }, [profile]);

  const handleInputChange = (field, value, type) => (event) => {
    let newValue = event.target.value;

    if (typeof newValue === 'undefined') newValue = event; // used for DropDownCustom component
    if (type === 'num' && newValue !== null) {
      let parsedNumber = (newValue || '').toString().replace(/,/g, '');
      parsedNumber = Math.floor(parsedNumber);
      if (!isNaN(parsedNumber)) newValue = parsedNumber;
      else newValue = undefined;
    }

    const newErrors = checkError(newValue, field, 'the amount you want to cash out', true);
    if (Object.entries(newErrors).length > 0) {
      setFieldCompleted(false);
      setErrors(newErrors);
    } else {
      dispatch({
        type: ACTIONS.STORE_PROFILE,
        data: { ...profile, [field]: newValue }
      });
      setCashOut(newValue);
      setErrors({});
      setFieldCompleted(true);
    }
  };

  const calculateMaxCashOut = (propertyCurrentMortgageBalance, propertyValue) => {
    const cw = Number.parseFloat(propertyValue);
    const ra = Number.parseFloat(propertyCurrentMortgageBalance);
    let mco;

    if (isNaN(cw) || isNaN(ra)) mco = 0;
    else mco = (cw * 0.8) - ra;

    return mco;
  };

  const onContinue = () => {
    pushDL('submitForm', {
      FormBody: {
        takeOutAmount: cashOut
      }
    }, null);
  }

  const handleEnter = (event) => {
    if (event.which === 13 && fieldCompleted) {
      onContinue();
      navigate(URLS.APPLY_SEVEN);
    }
  }

  return (
    <>
      <H2 bold>
        How much cash out would you like?
      </H2>
      <H2 bold>
        Looks like you can get up to {formatCurrency(maxCashOut)}.
      </H2>
      <InputAndButton>
        <div>
          <NoLabelNumberField
            id="cashOut"
            value={profile.cashOut ?? 0}
            onInputChange={handleInputChange('cashOut', null, 'num')}
            onKeyPress={handleEnter}
            border={false}
            rounded
            prefix="$"
            formatValue
            placeholder="Enter amount"
            autoFocus
          />
          <ButtonWrap>
            <Button disabled={!fieldCompleted} onClick={onContinue} to={fieldCompleted ? URLS.APPLY_SEVEN : null} linkComponent={Link}>Continue</Button>
          </ButtonWrap>
        </div>
        {errors.cashOut && (
          <ErrorMessage>{errors.cashOut}</ErrorMessage>
        )}
        {
          cashOut > maxCashOut
            ? (
              <>
                <ErrorMessage>
                  Aiming high, huh? That’s cool, just a heads-up though — typically you can only get up to 80% of your property’s value including your outstanding loan.
                </ErrorMessage>
                &nbsp;
                <br />
                <br />
              </>
            )
            : null
        }
      </InputAndButton>

    </>
  );
};

export default Step;